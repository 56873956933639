import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { Error } from "@material-ui/icons";

function ErrorDialog(props) {

    const handleMainAction = () => {
        props.onApply();
    };

    const handleCancelAction = () => {
        props.onCancel();
    };

    return (
        <Dialog fullWidth={true} maxWidth={"sm"} open={props.open}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
                <Error style={{ fontSize: 36, color: "red" }} /> Calculations with this input is not possible, there are no rates for the
                current input.
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelAction} color="secondary">
                    rollback
                </Button>
                <Button onClick={handleMainAction} color="secondary" variant={"contained"}>
                    search
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ErrorDialog.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onApply: PropTypes.func
};

export default withTheme()(ErrorDialog);
