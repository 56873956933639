import React from "react";
import { withTheme } from "@material-ui/core/styles";
import { Tooltip, Radio, FormControlLabel, RadioGroup } from "@material-ui/core";
import { Help } from "@material-ui/icons";

function Input(props) {
    return (
        <div className={"dialog-input"} style={{ margin: 0 }}>
            <div className={"input-label"}>
                <label>{props.label}</label>
            </div>
            <div className={"radio-field"}>
                <RadioGroup
                    aria-label={props.name}
                    name={props.name}
                    style={props.column !== null ? { flexDirection: props.column ? "column" : "row" } : {}}
                    value={props.value.toString()}
                    onChange={props.onChange}
                >
                    {props.inputs.map((input, index) => {
                        return <FormControlLabel key={index} value={input.value.toString()} control={<Radio />} label={input.label} />;
                    })}
                </RadioGroup>
            </div>
            <div className={"input-help"}>
                <Tooltip title={"help"}>
                    <Help style={{ color: props.theme.palette.secondary.main }} />
                </Tooltip>
            </div>
        </div>
    );
}

export default withTheme()(Input);
