import React, { Component, Fragment } from "react";
import { Card, CardContent } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import TextField from "../../Components/TextField/TextField";
import ChartContainer from "../../Components/ChartContainer/ChartContainer";
import { SubdirectoryArrowRight, CheckCircle, Close } from "@material-ui/icons";
import "./DashboardStyles.css";
import MortgageDialog from "../../Components/MortgageDialog/MortgageDialog";
import ApplicantsDialog from "../../Components/ApplicantsDialog/ApplicantsDialog";
import StartDateDialog from "../../Components/StartDateDialog/StartDateDialog";
import NumberOfPartsAndPartsMortgageAmountDialog from "../../Components/NumberOfPartsAndPartsMortgageAmountDialog/NumberOfPartsAndPartsMortgageAmountDialog";
import ProductSelector from "../../Components/ProductSelector/ProductSelector";
import MortgageTypeDialog from "../../Components/MortgageTypeDialog/MortgageTypeDialog";
import MortgageDurationDialog from "../../Components/MortgageDurationDialog/MortgageDurationDialog";
import MortgageInterestFixedPeriodDialog from "../../Components/MortgageInterestFixedPeriodDialog/MortgageInterestFixedPeriodDialog";
import ErrorDialog from "../../Components/ErrorDialog/ErrorDialog";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import BottomSelectionLogBar from "./../../Components/BottomSelectionLogBar/BottomSelectionLogBar";
import moment from "moment";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.productInput = React.createRef();

        this.mortgageInput = React.createRef();
        this.partsInput = React.createRef();
        this.applicantsInput = React.createRef();
        this.startDateInput = React.createRef();
        this.valueHouse = React.createRef();

        this.partOneMortgageTypeInput = React.createRef();
        this.partTwoMortgageTypeInput = React.createRef();

        this.partOneMortgageDurationInput = React.createRef();
        this.partTwoMortgageDurationInput = React.createRef();

        this.partOneMortgageFixedPeriodInput = React.createRef();
        this.partTwoMortgageFixedPeriodInput = React.createRef();

        this.partOneMortgageAmountInput = React.createRef();
        this.partTwoMortgageAmountInput = React.createRef();

        this.partOneIncomeInput = React.createRef();
        this.partTwoIncomeInput = React.createRef();

        this.state = {
            isProductDialogOpen: false,
            isRatesErrorDialogOpen: false,
            isMortgageDialogOpen: false,
            isApplicantsDialogOpen: false,
            isStartDateDialogOpen: false,
            isMortgageTypeDialogOpen: false,
            isMortgageDurationDialogOpen: false,
            isMortgageFixedPeriodDialogOpen: false,
            isNumberOfPartsAndMortgageAmountDialogOpen: false
        };
    }

    componentDidMount() {
        const { inputContext, logContext } = this.props;
        
        if (logContext.log !== null) {
            inputContext.loadStateFromLog(logContext.log);
        }
    }

    openDialog = dialogStateVariableName => {
        if (this.props.logContext.log === null) {
            this.setState({ [dialogStateVariableName]: true });
        }
    };

    closeDialog = (dialogStateVariableName, inputs) => {
        this.setState({ [dialogStateVariableName]: false }, () => {
            inputs.forEach(input => {
                if (input !== null) {
                    input.blur();
                }
            });
        });
    };

    handleSwitchChange = () => {
        this.setState(state => ({
            nhg: !state.nhg
        }));
    };

    removeLog = () => {
        this.props.inputContext.rollbackState(() => {
            this.props.logContext.unloadLog();
        });
    };

    render() {
        const { logContext, inputContext, authContext, sideMenuContext } = this.props;

        const {
            isProductDialogOpen,
            isMortgageDialogOpen,
            isNumberOfPartsAndMortgageAmountDialogOpen,
            isApplicantsDialogOpen,
            isStartDateDialogOpen,
            isMortgageTypeDialogOpen,
            isMortgageDurationDialogOpen,
            isMortgageFixedPeriodDialogOpen
        } = this.state;

        return (
            <Fragment>
                <PageWrapper
                    title={"Dashboard"}
                    shouldIncludeSideBar={true}
                    history={this.props.history}
                    sideMenuContext={sideMenuContext}
                    authContext={this.props.authContext}
                >
                    <div style={{ padding: 10 }}>
                        <Card style={{ flexWrap: "wrap" }}>
                            <CardContent className={"input-block"}>
                                <div className={"input-section"} style={{ width: "70%" }}>
                                    <div className={"input-row"}>
                                        <div className={"input-wrapper full"}>
                                            <ProductSelector
                                                id={"selected-product"}
                                                open={isProductDialogOpen}
                                                inputRef={this.productInput}
                                                onOpen={this.openDialog.bind(this, "isProductDialogOpen")}
                                                onClose={this.closeDialog.bind(this, "isProductDialogOpen", [this.productInput.current])}
                                                name={"product"}
                                                label={"Product"}
                                                logContext={logContext}
                                                authContext={authContext}
                                            />
                                        </div>
                                    </div>
                                    <div className={"input-row"}>
                                        <div className={"input-wrapper medium"}>
                                            <TextField
                                                value={inputContext.mortgageAmount.value}
                                                label={"Mortgage"}
                                                type={"text"}
                                                inputRef={this.mortgageInput}
                                                disabled={logContext.log !== null}
                                                prefix={"€"}
                                                onClick={this.openDialog.bind(this, "isMortgageDialogOpen")}
                                            />
                                        </div>
                                        <div className={"input-wrapper small"}>
                                            <TextField
                                                value={inputContext.parts.parts.length}
                                                label={"Parts"}
                                                type={"text"}
                                                inputRef={this.partsInput}
                                                disabled={logContext.log !== null}
                                                onClick={this.openDialog.bind(this, "isNumberOfPartsAndMortgageAmountDialogOpen")}
                                            />
                                        </div>
                                        <div className={"input-wrapper small"}>
                                            <TextField
                                                value={
                                                    inputContext.isNhgPossible.value
                                                        ? !inputContext.forceNoNhg.value
                                                            ? "Yes"
                                                            : "No"
                                                        : "No"
                                                }
                                                disabled={true}
                                                label={"NHG"}
                                                type={"text"}
                                            />
                                        </div>
                                        <div className={"input-wrapper medium"}>
                                            <TextField
                                                value={inputContext.startDate.value}
                                                label={"Start date"}
                                                disabled={logContext.log !== null}
                                                inputRef={this.startDateInput}
                                                onClick={this.openDialog.bind(this, "isStartDateDialogOpen")}
                                                type={"text"}
                                            />
                                        </div>
                                        <div className={"input-wrapper medium"}>
                                            <TextField
                                                value={inputContext.valueHouse.value}
                                                label={"House"}
                                                disabled={logContext.log !== null}
                                                inputRef={this.valueHouse}
                                                onClick={this.openDialog.bind(this, "isMortgageDialogOpen")}
                                                type={"text"}
                                                prefix={"€"}
                                            />
                                        </div>
                                        <div className={"input-wrapper medium"}>
                                            <TextField value={`${inputContext.ltv.value}%`} label={"LTV"} type={"text"} disabled={true} />
                                        </div>
                                    </div>
                                    {inputContext.parts.parts.map((part, key) => {
                                        return (
                                            <div className={"input-row"} key={key}>
                                                <div className={"part-arrow"}>
                                                    <SubdirectoryArrowRight />
                                                </div>
                                                <div className={"input-wrapper medium"}>
                                                    <TextField
                                                        value={part.mortgageAmount.value}
                                                        label={`Part ${key + 1}`}
                                                        disabled={logContext.log !== null}
                                                        onClick={this.openDialog.bind(this, "isNumberOfPartsAndMortgageAmountDialogOpen")}
                                                        inputRef={
                                                            key === 0 ? this.partOneMortgageAmountInput : this.partTwoMortgageAmountInput
                                                        }
                                                        type={"text"}
                                                        prefix={"€"}
                                                    />
                                                </div>
                                                <div className={"input-wrapper medium"}>
                                                    <TextField
                                                        value={part.mortgageType.value}
                                                        disabled={logContext.log !== null}
                                                        onClick={this.openDialog.bind(this, "isMortgageTypeDialogOpen")}
                                                        inputRef={key === 0 ? this.partOneMortgageTypeInput : this.partTwoMortgageTypeInput}
                                                        label={"Type"}
                                                        type={"text"}
                                                    />
                                                </div>
                                                <div className={"input-wrapper medium"}>
                                                    <TextField
                                                        value={part.duration.value}
                                                        label={"Duration"}
                                                        inputRef={
                                                            key === 0
                                                                ? this.partOneMortgageDurationInput
                                                                : this.partTwoMortgageDurationInput
                                                        }
                                                        disabled={logContext.log !== null}
                                                        onClick={this.openDialog.bind(this, "isMortgageDurationDialogOpen")}
                                                        type={"text"}
                                                    />
                                                </div>
                                                <div className={"input-wrapper medium"}>
                                                    <TextField
                                                        value={part.interestFixedPeriod.value}
                                                        inputRef={
                                                            key === 0
                                                                ? this.partOneMortgageFixedPeriodInput
                                                                : this.partTwoMortgageFixedPeriodInput
                                                        }
                                                        disabled={logContext.log !== null}
                                                        onClick={this.openDialog.bind(this, "isMortgageFixedPeriodDialogOpen")}
                                                        label={"Interest fixed"}
                                                        type={"text"}
                                                    />
                                                </div>
                                                <div className={"input-wrapper medium"}>
                                                    <TextField
                                                        value={`${(parseFloat(part.interestRate.value) * 100).toFixed(2)}%`}
                                                        disabled={true}
                                                        label={"Interest"}
                                                        type={"text"}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className={"input-section"} style={{ width: "20%" }}>
                                    <div className={"input-row"}>
                                        <div className={"input-wrapper full"}>
                                            <TextField
                                                value={inputContext.applicants.incomes.length}
                                                label={"Applicants"}
                                                type={"text"}
                                                disabled={logContext.log !== null}
                                                inputRef={this.applicantsInput}
                                                onClick={this.openDialog.bind(this, "isApplicantsDialogOpen")}
                                            />
                                        </div>
                                    </div>
                                    {inputContext.applicants.incomes.map((income, key) => {
                                        return (
                                            <div key={key} className={"input-row"}>
                                                <div className={"input-wrapper full"}>
                                                    <TextField
                                                        disabled={logContext.log !== null}
                                                        value={income.value}
                                                        onClick={this.openDialog.bind(this, "isApplicantsDialogOpen")}
                                                        inputRef={key === 0 ? this.partOneIncomeInput : this.partTwoIncomeInput}
                                                        label={`Income ${key + 1}`}
                                                        type={"text"}
                                                        prefix={"€"}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className={"input-section"} style={{ width: "10%", alignItems: "center" }}>
                                    <div className={"input-row"} style={{ justifyContent: "center" }}>
                                        {inputContext.mortgageAmount.value > inputContext.maxMortgageAmountIncome.value ? (
                                            <Close className={"input-check"} />
                                        ) : (
                                            <CheckCircle className={"input-check"} />
                                        )}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <ChartContainer
                            chartType={"BarChart"}
                            chartData={inputContext.netPaymentData}
                            isChartDataLoading={inputContext.isChartDataLoading}
                            sideMenuContext={sideMenuContext}
                            totals={inputContext.totalNetCalculations}
                            totalsHeader={"Total NET payments"}
                        />
                        <ChartContainer
                            chartType={"BarChart"}
                            chartData={inputContext.grossPaymentData}
                            isChartDataLoading={inputContext.isChartDataLoading}
                            sideMenuContext={sideMenuContext}
                            totals={inputContext.totalGrossCalculations}
                            totalsHeader={"Total gross payments"}
                        />
                        <ChartContainer
                            chartType={"BarChart"}
                            chartData={inputContext.averageInterestRateData}
                            isChartDataLoading={inputContext.isChartDataLoading}
                            sideMenuContext={sideMenuContext}
                            totals={inputContext.averageInterestRates}
                            totalsHeader={"Average weighted interest rate"}
                            isTotalValueWholeNumber={false}
                        />
                        <ChartContainer
                            chartType={"AreaChart"}
                            chartData={inputContext.mortgageAmountData}
                            sideMenuContext={sideMenuContext}
                            isChartDataLoading={inputContext.isChartDataLoading}
                        />
                    </div>
                    {this.props.logContext.log !== null ? <div style={{ marginTop: 50 }} /> : undefined}
                    <BottomSelectionLogBar
                        sideMenuContext={this.props.sideMenuContext}
                        selectedLogs={this.props.logContext.log === null ? [] : [this.props.logContext.log]}
                        closeAction={this.removeLog}
                        text={
                            this.props.logContext.log === null
                                ? null
                                : `log from ${moment(this.props.logContext.log.mils, "x").format("HH:mm, MMM DD, YYYY")} loaded`
                        }
                    />
                </PageWrapper>
                <MortgageDialog
                    logContext={logContext}
                    open={isMortgageDialogOpen}
                    onClose={this.closeDialog.bind(this, "isMortgageDialogOpen", [this.mortgageInput.current, this.valueHouse.current])}
                />
                <ApplicantsDialog
                    logContext={logContext}
                    open={isApplicantsDialogOpen}
                    onClose={this.closeDialog.bind(this, "isApplicantsDialogOpen", [
                        this.applicantsInput.current,
                        this.partOneIncomeInput.current,
                        this.partTwoIncomeInput.current
                    ])}
                />
                <StartDateDialog
                    logContext={logContext}
                    open={isStartDateDialogOpen}
                    onClose={this.closeDialog.bind(this, "isStartDateDialogOpen", [this.startDateInput.current])}
                />
                <MortgageTypeDialog
                    logContext={logContext}
                    open={isMortgageTypeDialogOpen}
                    onClose={this.closeDialog.bind(this, "isMortgageTypeDialogOpen", [
                        this.partOneMortgageTypeInput.current,
                        this.partTwoMortgageTypeInput.current
                    ])}
                />
                <MortgageDurationDialog
                    logContext={logContext}
                    open={isMortgageDurationDialogOpen}
                    onClose={this.closeDialog.bind(this, "isMortgageDurationDialogOpen", [
                        this.partOneMortgageDurationInput.current,
                        this.partTwoMortgageDurationInput.current
                    ])}
                />
                <MortgageInterestFixedPeriodDialog
                    logContext={logContext}
                    open={isMortgageFixedPeriodDialogOpen}
                    onClose={this.closeDialog.bind(this, "isMortgageFixedPeriodDialogOpen", [
                        this.partOneMortgageFixedPeriodInput.current,
                        this.partTwoMortgageFixedPeriodInput.current
                    ])}
                />
                <ErrorDialog
                    open={inputContext.rateError}
                    onCancel={() => {
                        this.props.input.rollbackState();
                    }}
                    onApply={this.openDialog.bind(this, "isProductDialogOpen")}
                />
                <NumberOfPartsAndPartsMortgageAmountDialog
                    logContext={logContext}
                    open={isNumberOfPartsAndMortgageAmountDialogOpen}
                    onClose={this.closeDialog.bind(this, "isNumberOfPartsAndMortgageAmountDialogOpen", [
                        this.partsInput.current,
                        this.partOneMortgageAmountInput.current,
                        this.partTwoMortgageAmountInput.current
                    ])}
                />
            </Fragment>
        );
    }
}
export default withTheme()(Dashboard);
