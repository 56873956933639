import React, { Component } from "react";
import PropTypes from "prop-types";
import { InputContext } from "./../../Contexts/InputContext";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import { Paper, Table, TableBody, TableCell, TableRow, TableHead, Typography, Checkbox, IconButton } from "@material-ui/core";
import moment from "moment";
import "./HistoryStyles.css";
import BottomSelectionLogBar from "./../../Components/BottomSelectionLogBar/BottomSelectionLogBar";
import { Warning, Check, MoreVert } from "@material-ui/icons";

class History extends Component {
    static contextType = InputContext;

    static propTypes = {
        authContext: PropTypes.object.isRequired,
        sideMenuContext: PropTypes.object.isRequired,
        logContext: PropTypes.object.isRequired,
        inputContext: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.lastLog = React.createRef();

        this.state = {
            selectedLogs: [],
            openedLogs: [],
            loadingOlderLogs: false
        };
    }

    componentDidMount() {
        const { logContext } = this.props;

        logContext.getLogs();

        window.addEventListener("scroll", this.scrollEvent);
    }

    handleToggle = value => () => {
        const { selectedLogs } = this.state;
        const currentIndex = selectedLogs.indexOf(value);
        const newChecked = [...selectedLogs];

        if (currentIndex === -1) {
            newChecked.push(value);
            //limiting to only one item being able to select
            if (newChecked.length > 1) {
                newChecked.shift();
            }
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            selectedLogs: newChecked
        });
    };

    handleToggleItem = value => {
        const { openedLogs } = this.state;
        const currentIndex = openedLogs.indexOf(value);
        const newChecked = [...openedLogs];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            openedLogs: newChecked
        });
    };

    scrollEvent = () => {
        const { logContext } = this.props;
        if (this.lastLog.current !== null && !this.state.loadingOlderLogs) {
            const windowHeight = window.innerHeight;

            if (window.scrollY + windowHeight > this.offsetEl(this.lastLog.current).top) {
                this.setState({ loadingOlderLogs: true }, () => {
                    logContext.loadOlderLogs(() => {
                        this.setState({ loadingOlderLogs: false });
                    });
                });
            }
        }
    };

    offsetEl = el => {
        const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    };

    loadLog = () => {
        const { logContext, inputContext } = this.props;

        const itemId = this.state.selectedLogs[0];

        if (logContext.log !== null) {
            inputContext.rollbackState(() => {
                this.requestLog(itemId);
            });
        } else {
            this.requestLog(itemId);
        }
    };

    requestLog = itemId => {
        const { logContext, inputContext, history } = this.props;
        logContext.getLog(itemId, () => {
            inputContext.createStateCheckPoint(() => {
                history.push("/dashboard");
            });
        });
    };

    closeSelectionBar = () => {
        
    };

    render() {
        const { logContext, sideMenuContext } = this.props;

        return (
            <PageWrapper
                title={"History"}
                shouldIncludeSideBar={true}
                history={this.props.history}
                sideMenuContext={sideMenuContext}
                authContext={this.props.authContext}
            >
                <div className="dom history-dom">
                    {logContext.logs.length > 0 ? (
                        logContext.logs.map((logsForDays, index) => {
                            const today = new Date();

                            let formatedDate = logsForDays.formatedDate;

                            if (logsForDays.date.getDate() === today.getDate()) {
                                formatedDate = "Today - " + formatedDate;
                            } else if (Math.abs(logsForDays.date.getDate() - today.getDate()) === 1) {
                                formatedDate = "Yesterday - " + formatedDate;
                            }

                            return (
                                <div className={"history-day"} key={index}>
                                    <Typography style={{ marginTop: index === 0 ? 20 : 40, marginBottom: 10 }} variant={"h5"}>
                                        {formatedDate}
                                    </Typography>
                                    <Paper>
                                        <Table className="history-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={"small-item"} />
                                                    <TableCell className={"small-item"} />
                                                    <TableCell className={"small-item"} />
                                                    <TableCell className={"medium-item"} component="th" align="left">
                                                        Mortgage amount
                                                    </TableCell>
                                                    <TableCell className={"small-item"} component="th" align="left">
                                                        Parts
                                                    </TableCell>
                                                    <TableCell className={"medium-item"} component="th" align="left">
                                                        amount 1
                                                    </TableCell>
                                                    <TableCell className={"medium-item"} component="th" align="left">
                                                        type 1
                                                    </TableCell>
                                                    <TableCell className={"small-item"} component="th" align="left">
                                                        ifp 1
                                                    </TableCell>
                                                    <TableCell className={"medium-item"} component="th" align="left">
                                                        amount 2
                                                    </TableCell>
                                                    <TableCell className={"medium-item"} component="th" align="left">
                                                        type 2
                                                    </TableCell>
                                                    <TableCell className={"small-item"} component="th" align="left">
                                                        ifp 2
                                                    </TableCell>
                                                    <TableCell className={"big-item"} component="th" align="left">
                                                        Product
                                                    </TableCell>
                                                    <TableCell className={"small-item"} component="th" align="left" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {logsForDays.logs.map((log, logIndex) => {
                                                    return (
                                                        <TableRow
                                                            key={log.id}
                                                            hover
                                                            onClick={this.handleToggle(log.id)}
                                                            component={props => {
                                                                return (
                                                                    <tr
                                                                        className={props.className}
                                                                        onClick={props.onClick}
                                                                        ref={
                                                                            typeof logContext.logs !== "undefined" &&
                                                                            typeof logsForDays.logs !== "undefined"
                                                                                ? logContext.logs.length - 1 === index &&
                                                                                  logIndex === logsForDays.logs.length - 1
                                                                                    ? this.lastLog
                                                                                    : null
                                                                                : null
                                                                        }
                                                                    >
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                color={"secondary"}
                                                                                checked={this.state.selectedLogs.indexOf(log.id) !== -1}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {log.input.mortgage_amount <
                                                                            log.calculation.max_mortgage_from_income ? (
                                                                                <Check className={"icon-check"} />
                                                                            ) : (
                                                                                <Warning className={"icon-warning"} />
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {moment(log.mils, "x").format("HH:mm")}
                                                                        </TableCell>
                                                                        <TableCell align="left">{log.input.mortgage_amount}</TableCell>
                                                                        <TableCell align="left">{log.input.number_of_parts}</TableCell>
                                                                        <TableCell align="left">
                                                                            {log.input.parts[0].mortgageAmount.value}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {log.input.parts[0].mortgageType.value}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {log.input.parts[0].interestFixedPeriod.value}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {log.input.parts.length > 1
                                                                                ? log.input.parts[1].mortgageAmount.value
                                                                                : "-"}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {log.input.parts.length > 1
                                                                                ? log.input.parts[1].mortgageType.value
                                                                                : "-"}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {log.input.parts.length > 1
                                                                                ? log.input.parts[1].interestFixedPeriod.value
                                                                                : "-"}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {log.current_product.current_product.name}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <IconButton
                                                                                onClick={event => {
                                                                                    event.stopPropagation();
                                                                                    // this.handleToggleItem(log.id);
                                                                                }}
                                                                            >
                                                                                <MoreVert />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </tr>
                                                                );
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </div>
                            );
                        })
                    ) : (
                        <Typography variant={"subtitle1"}>No history.</Typography>
                    )}
                </div>
                {this.state.selectedLogs.length > 0 ? <div style={{ marginTop: 40 }} /> : undefined}
                <BottomSelectionLogBar
                    sideMenuContext={sideMenuContext}
                    selectedLogs={this.state.selectedLogs}
                    primaryAction={this.loadLog}
                    closeAction={this.closeSelectionBar}
                />
            </PageWrapper>
        );
    }
}

export default History;
