import React from "react";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import { Typography, Button } from "@material-ui/core";

export default function Home(props) {
    const redirectToDashboard = () => {
        props.history.push("/dashboard");
    };

    return (
        <PageWrapper
            title={"Home"}
            shouldIncludeSideBar={false}
            contentFullHeight={true}
            history={props.history}
            sideMenuContext={props.sideMenuContext}
            authContext={props.authContext}
        >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column" }}>
                <Typography variant={"h2"}>Hyponav</Typography>
                <Button color="primary" variant={"text"} onClick={redirectToDashboard}>
                    dashboard
                </Button>
            </div>
        </PageWrapper>
    );
}
