import React from "react";
import { Paper, Typography } from "@material-ui/core";
import "./EmailSendStyles.css";

export default class EmailSend extends React.Component {
    render() {
        return (
            <Paper className={"dom"}>
                <Typography variant={"h2"}> Email send!</Typography>
                <Typography>Thank you! You should receive the email shortly.</Typography>
            </Paper>
        );
    }
}
