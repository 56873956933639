import React, { Component } from "react";
import PropTypes from "prop-types";
import { KeyboardArrowRight } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import "./ChartScrollButtonStyles.css";

class ChartScrollButton extends Component {
    static propTypes = {
        chartDomRef: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            isButtonVisible: true,
            isIE: /*@cc_on!@*/ false || !!document.documentMode
        };

        this.updateButton = event => {
            const { chartDomRef } = this.props;

            const dom = chartDomRef.current;

            if (dom.clientWidth + dom.scrollLeft === dom.scrollWidth) {
                this.setState({ isButtonVisible: false });
            } else {
                this.setState({ isButtonVisible: true });
            }
        };
    }

    componentDidMount() {
        const { chartDomRef } = this.props;
        
        chartDomRef.current.addEventListener("scroll", this.updateButton);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isButtonVisible } = this.state;

        return isButtonVisible !== nextState.isButtonVisible;
    }

    componentWillUnmount() {
        const { chartDomRef } = this.props;
        
        if (chartDomRef.current !== null) {
            chartDomRef.current.removeEventListener("scroll", this.updateButton);
        }
    }

    scrollChart = () => {
        const { chartDomRef } = this.props;

        chartDomRef.current.scrollLeft += 200;
    };

    render() {
        const { theme } = this.props;
        const { isButtonVisible, isIE } = this.state;

        return isButtonVisible && !isIE ? (
            <IconButton
                className={"chart-scroller"}
                onClick={this.scrollChart}
                style={{ backgroundColor: theme.palette.secondary.main, color: "white" }}
            >
                <KeyboardArrowRight />
            </IconButton>
        ) : null;
    }
}

export default withTheme()(ChartScrollButton);
