import React, { Component, Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core/styles";
import { IconButton, Tooltip, Button, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "./BottomSelectionLogBarStyles.css";

class BottomSelectionLogBar extends Component {
    static propTypes = {
        sideMenuContext: PropTypes.object.isRequired,
        selectedLogs: PropTypes.array.isRequired,
        text: PropTypes.string,
        closeAction: PropTypes.func.isRequired,
        closeActionText: PropTypes.string,
        secondaryAction: PropTypes.func,
        secondaryActionText: PropTypes.string,
        primaryAction: PropTypes.func,
        primaryActionText: PropTypes.string
    };

    static defaultProps = {
        closeActionText: null,
        secondaryActionText: null,
        text: null,
        primaryActionText: null
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    shouldComponentUpdate(prevProps, prevState) {
        const { selectedLogs, sideMenuContext } = this.props;

        return (
            prevProps.selectedLogs.length !== selectedLogs.length ||
            sideMenuContext.isSideMenuOpen !== prevProps.sideMenuContext.isSideMenuOpen
        );
    }

    render() {
        const {
            sideMenuContext,
            selectedLogs,
            closeAction,
            primaryAction,
            primaryActionText,
            secondaryAction,
            secondaryActionText,
            closeActionText,
            text
        } = this.props;

        return selectedLogs.length > 0 ? (
            <div
                className={classNames("bottom-selection-app-bar", {
                    "side-menu-open-animation": !sideMenuContext.isSideMenuOpen,
                    "side-menu-close-animation": sideMenuContext.isSideMenuOpen
                })}
                style={{
                    width: sideMenuContext.isSideMenuOpen
                        ? `calc(100% - ${sideMenuContext.drawerWidthOpen}px)`
                        : `calc(100% - ${sideMenuContext.drawerWidthClose}px)`,
                    backgroundColor: this.props.theme.palette.primary.main,
                    color: this.props.theme.palette.primary.contrastText,
                    marginLeft: sideMenuContext.isSideMenuOpen ? sideMenuContext.drawerWidthOpen : sideMenuContext.drawerWidthClose
                }}
            >
                <div className={"bottom-selection-content"}>
                    <Tooltip
                        title={closeActionText !== null ? closeActionText : "Close"}
                        aria-label={closeActionText !== null ? closeActionText : "Close"}
                        placement="top"
                    >
                        <IconButton color="inherit" aria-label={closeActionText !== null ? closeActionText : "Close"} onClick={closeAction}>
                            <Close />
                        </IconButton>
                    </Tooltip>
                    <Typography style={{ color: this.props.theme.palette.primary.contrastText }}>
                        {text !== null ? text : `${selectedLogs.length} log selected`}
                    </Typography>
                </div>
                <div className={"bottom-selection-action"}>
                    {typeof secondaryAction !== "undefined" ? (
                        <Button variant="contained" color="secondary" onClick={secondaryAction}>
                            {secondaryActionText !== null ? secondaryActionText : "unload"}
                        </Button>
                    ) : (
                        undefined
                    )}
                    {typeof primaryAction !== "undefined" ? (
                        <Button variant="contained" color="secondary" onClick={primaryAction}>
                            {primaryActionText !== null ? primaryActionText : "load"}
                        </Button>
                    ) : (
                        undefined
                    )}
                </div>
            </div>
        ) : (
            <Fragment />
        );
    }
}
export default withTheme()(BottomSelectionLogBar);
