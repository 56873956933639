
const firebase = {
    config: {
        apiKey: "AIzaSyDdd7CT-IwGin_zfeIy-t8PqUgy9zthLv8",
        authDomain: "hyponav.firebaseapp.com",
        databaseURL: "https://hyponav.firebaseio.com",
        projectId: "hyponav",
        storageBucket: "hyponav.appspot.com",
        messagingSenderId: "631570766179",
        appId: "1:631570766179:web:21dd6ca76e100a9e"
    }
};

const api = { url: "https://api.arverasupport.nl/v1" };

module.exports = { firebase, api };