import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { Card, Typography, CardContent, TextField, Button, FormControl, FormHelperText, CircularProgress } from "@material-ui/core";
import "./ResetPasswordStyles.css";

export default class ResetPassword extends React.Component {
    state = {
        email: "",
        errors: {
            email: false
        },
        isLoading: false
    };

    onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    sendEmail = () => {
        const { email, errors } = this.state;
        this.setState({ isLoading: true });

        let _errors = errors;

        if (email.indexOf("@") >= 0) {
            _errors.email = false;
            this.setState({ errors: _errors });

            firebase
                .auth()
                .sendPasswordResetEmail(email)
                .then(() => {
                    this.props.history.push("/send");
                })
                .catch(() => {
                    _errors.email = true;
                    this.setState({ errors: _errors, isLoading: false });
                });
        } else {
            _errors.email = true;
            this.setState({ errors: _errors, isLoading: false });
        }
    };

    redirectToLogin = () => {
        this.props.history.push("/login");
    };

    render() {
        const { errors, isLoading } = this.state;

        return (
            <div className={"dom"}>
                <Card className={"card"}>
                    <CardContent className={"card-content"}>
                        <Typography variant={"h2"} style={{ margin: "20px 0" }}>
                            Reset
                        </Typography>
                        <FormControl error={errors.email} className={"sign-form-input"}>
                            <TextField
                                error={errors.email}
                                label="Email"
                                variant={"outlined"}
                                className={"sign-input"}
                                type="email"
                                name="email"
                                onChange={this.onInputChange}
                                autoComplete="email"
                                margin="normal"
                            />
                            {errors.email ? (
                                <FormHelperText className={"error-message"}>
                                    Oops! The email is not valid or it does not exists.
                                </FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <Button size="large" color="secondary" variant="contained" className={"sign-in-button"} onClick={this.sendEmail}>
                            {isLoading ? <CircularProgress className={"loader"} /> : "Send"}
                        </Button>
                        <div className={"sign-help"}>
                            <Button variant="text" color="primary" onClick={this.redirectToLogin}>
                                Remembered your password?
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
