import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip, Select, MenuItem } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { InputContext } from "../../Contexts/InputContext";
import { Help } from "@material-ui/icons";

const BootstrapInput = withStyles(theme => ({
    root: {
        "label + &": {
            marginTop: theme.spacing.unit * 3
        }
    },
    input: {
        borderRadius: 4,
        position: "relative",
        border: "1px solid #ced4da",
        fontSize: 16,
        width: "auto",
        padding: "10px 26px 10px 12px",
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(","),
        "&:focus": {
            borderColor: theme.palette.primary.main,
            boxShadow: `0 0 0 1.5px ${theme.palette.primary.main}`
        }
    }
}))(InputBase);

class StartDateDialog extends Component {
    static contextType = InputContext;

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func
    };

    constructor(props) {
        super(props);

        const currentDate = new Date();

        this.state = {
            lastValue: "",
            day: "1",
            month: currentDate.getMonth() + 2,
            year: currentDate.getMonth() + 1 === 12 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open === true && prevProps.open !== this.props.open) {
            this.setState({ lastValue: this.context.startDate.value });
        }
    }

    handleMainAction = () => {
        const inputContext = this.context;
        const { onClose } = this.props;
        const { day, month, year } = this.state;
        //some action

        inputContext.startDate.setValue(day, month, year, () => {
            onClose();
            inputContext.currentProduct.setValue(inputContext.currentProduct.value.code, () => {
                inputContext.requestChartsData(() => {
                    this.props.logContext.createLog();
                });
            });
        });
    };

    handleCancelAction = () => {
        const { onClose } = this.props;

        onClose();
    };

    handleMonthChange = event => {
        const newMonth = event.target.value;

        const currentDate = new Date();

        if (newMonth < currentDate.getMonth() + 1) {
            this.setState({ month: newMonth, year: currentDate.getFullYear() + 1 });
        } else {
            this.setState({ month: newMonth, year: currentDate.getFullYear() });
        }
    };

    render() {
        const { open } = this.props;

        return (
            <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
                <DialogTitle>Mortgage start date</DialogTitle>
                <DialogContent className={"dialog-content"}>
                    <div className={"content-wrapper"}>
                        <div className={"content-side"}>
                            <div className={"dialog-input"}>
                                <div className={"input-label"}>
                                    <label>Start date</label>
                                </div>
                                <div style={{ display: "flex", width: "45%" }}>
                                    <div className={"input-field"} style={{ width: "20%", marginRight: 5 }}>
                                        <input value={this.state.day} readOnly={true} />
                                    </div>
                                    <Select
                                        style={{ width: "39%", marginRight: 5 }}
                                        value={this.state.month}
                                        onChange={this.handleMonthChange}
                                        input={<BootstrapInput name="month" id="month-input" />}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
                                            return (
                                                <MenuItem key={month} value={month}>
                                                    {month}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <div className={"input-field"} style={{ width: "39%" }}>
                                        <input value={this.state.year} readOnly={true} />
                                    </div>
                                </div>
                                <div className={"input-help"}>
                                    <Tooltip title={"help"}>
                                        <Help style={{ color: this.props.theme.palette.secondary.main }} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className={"content-side"}>
                            “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                            laborum.”
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancelAction} color="secondary">
                        cancel
                    </Button>
                    <Button onClick={this.handleMainAction} color="secondary" variant={"contained"}>
                        apply
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTheme()(StartDateDialog);
