import React from "react";
import { withTheme } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";

function Input(props) {
    return (
        <div className={"dialog-input"}>
            <div className={"input-label"}>
                <label htmlFor={props.id}>{props.label}</label>
            </div>
            <div className={"input-field"}>
                <input
                    className={props.error ? "error" : ""}
                    id={props.id}
                    type={props.type}
                    value={props.value}
                    onChange={props.onChange}
                    readOnly={props.readOnly}
                />
                {props.error !== null ? <Typography className={"input-error"}>{props.errors[props.error]}</Typography> : undefined}
            </div>
            <div className={"input-help"}>
                <Tooltip title={"help"}>
                    <Help style={{ color: props.theme.palette.secondary.main }} />
                </Tooltip>
            </div>
        </div>
    );
}

Input.defaultProps = {
    error: null,
    type: "number",
    readOnly: false
};

export default withTheme()(Input);
