import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    Checkbox,
    ListItemText,
    ListItemSecondaryAction,
    CircularProgress,
    Tooltip,
    IconButton
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import TextField from "./../TextField/TextField";
import CustomSelect from "./../Select/Select";
import "./ProductsSelectorStyles.css";
import { Help } from "@material-ui/icons";
import { InputContext } from "../../Contexts/InputContext";
import { api } from "./../../services";

class ProductSelector extends Component {
    static contextType = InputContext;

    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        open: PropTypes.bool.isRequired,
        onOpen: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        authContext: PropTypes.object.isRequired,
        logContext: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.labelRef = React.createRef();

        this.state = {
            labelWidth: 0,
            products: [],
            areProductsLoading: false,
            selectedProduct: null,
            selectedProductCode: ""
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open !== this.props.open && this.props.open === true) {
            this.searchProducts();
        }
    }

    selectProduct = (key, code) => {
        this.setState({ selectedProduct: key, selectedProductCode: code });
    };

    handleNewProductSelection = () => {
        const { currentProduct } = this.context;
        const { selectedProductCode } = this.state;

        currentProduct.setValue(selectedProductCode, () => {
            this.props.onClose();
            this.context.requestChartsData(() => {
                this.props.logContext.createLog();
            });
        });
    };

    searchProducts = () => {
        this.setState({ areProductsLoading: true });

        this.requestProducts(this.showResults);
    };

    showResults = () => {
        setTimeout(() => {
            this.setState({ areProductsLoading: false, selectedProduct: null });
        }, 500);
    };

    requestProducts = callback => {
        const { mortgageAmount, valueHouse, isNhgPossible, forceNoNhg, parts } = this.context;

        const nhgForRequest = isNhgPossible.value ? !forceNoNhg.value : false;

        const requestURL = `${api.url}/products?mortgage_amount=${mortgageAmount.value}&house_value=${
            valueHouse.value
        }&nhg=${nhgForRequest}&parts=${parts.toStringRequest()}`;

        axios({
            method: "get",
            url: requestURL,
            headers: { Authorization: `Bearer ${this.props.authContext.token}` }
        })
            .then(response => {
                const products = response.data.data;

                products.sort(this.sortProducts(true));

                this.setState({ products: products }, () => {
                    
                    if (typeof callback !== "undefined") {
                        callback();
                    }
                });
            })
            .catch(error => {
                
            });
    };

    sortProducts = ascending => {
        return function(a, b) {
            if (a.parts[0]["interest_rate"] === null) {
                return 1;
            } else if (b.parts[0]["interest_rate"] === null) {
                return -1;
            } else if (a.parts[0]["interest_rate"] === b.parts[0]["interest_rate"]) {
                return 0;
            } else if (ascending) {
                return a.parts[0]["interest_rate"] < b.parts[0]["interest_rate"] ? -1 : 1;
            } else if (!ascending) {
                return a.parts[0]["interest_rate"] < b.parts[0]["interest_rate"] ? 1 : -1;
            }
        };
    };

    render() {
        const inputContext = this.context;
        const { label, theme, open } = this.props;
        const { areProductsLoading, selectedProduct } = this.state;

        const numberOfParts = inputContext.parts.parts.length;

        return (
            <Fragment>
                <TextField
                    inputRef={this.props.inputRef}
                    value={inputContext.currentProduct.value.description}
                    label={label}
                    type={"text"}
                    disabled={this.props.logContext.log !== null}
                    onClick={this.props.onOpen}
                />
                <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
                    <DialogTitle>Choose product</DialogTitle>
                    <DialogContent className={"dialog-content"}>
                        <div className={"content-wrapper"}>
                            <div className={"content-side product-selection-input-box"}>
                                <TextField
                                    disabled={true}
                                    value={inputContext.mortgageAmount.value}
                                    label={"Mortgage amount"}
                                    type={"text"}
                                    prefix={"€"}
                                />
                                <TextField
                                    value={inputContext.valueHouse.value}
                                    disabled={true}
                                    label={"Value house"}
                                    prefix={"€"}
                                    type={"text"}
                                />
                                <TextField
                                    value={inputContext.isNhgPossible.value ? (!inputContext.forceNoNhg.value ? "Yes" : "No") : "No"}
                                    disabled={true}
                                    label={"NHG"}
                                    type={"text"}
                                />
                                <TextField value={`${inputContext.ltv.value}%`} disabled={true} label={"LTV"} type={"text"} />
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {inputContext.parts.parts.map((part, key) => {
                                        return (
                                            <div
                                                key={key}
                                                style={{
                                                    width: `${100 / numberOfParts}%`,
                                                    marginRight: numberOfParts > 1 && key === 0 ? "20px" : 0
                                                }}
                                            >
                                                <CustomSelect
                                                    id={`mortgage-type-${key}`}
                                                    name={`mortgage-type-${key}`}
                                                    label={`Part ${key + 1} type`}
                                                    value={part.mortgageType.requestValue}
                                                    onChange={event => {
                                                        inputContext.parts.setPartMortgageTypeRequest(event.target.value, key);
                                                    }}
                                                    options={part.mortgageType.options}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    {inputContext.parts.parts.map((part, key) => {
                                        return (
                                            <div
                                                key={key}
                                                style={{
                                                    width: `${100 / numberOfParts}%`,
                                                    marginRight: numberOfParts > 1 && key === 0 ? "20px" : 0
                                                }}
                                            >
                                                <CustomSelect
                                                    id={`interest-fixed-period-${key}`}
                                                    name={`interest-fixed-period-${key}`}
                                                    label={`Part ${key + 1} period`}
                                                    value={part.interestFixedPeriod.requestValue}
                                                    onChange={event => {
                                                        inputContext.parts.setPartInterestFixedPeriodRequest(event.target.value, key);
                                                    }}
                                                    options={part.interestFixedPeriod.options}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <Button variant={"contained"} onClick={this.searchProducts} color={"secondary"}>
                                        Search
                                    </Button>
                                </div>
                            </div>
                            <div className={"content-side"} style={{ overflowY: "auto" }}>
                                {areProductsLoading ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            height: "100%",
                                            width: "100%",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <List component="div">
                                        {this.state.products.map((product, index) => {
                                            return (
                                                <ListItem button key={index} onClick={this.selectProduct.bind(this, index, product.code)}>
                                                    <Checkbox
                                                        checked={selectedProduct !== null ? selectedProduct === index : false}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                    <ListItemText
                                                        className={"list-item-text"}
                                                        primary={product.name}
                                                        secondary={product.description}
                                                    />
                                                    <ListItemSecondaryAction style={{ display: "flex", flexDirection: "row" }}>
                                                        {product.parts.map((part, key) => {
                                                            const rate = (parseFloat(part.interest_rate) * 100).toFixed(2);
                                                            return (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        width: "50px",
                                                                        alignItems: "center",
                                                                        justifyContent: "center"
                                                                    }}
                                                                    key={key}
                                                                >
                                                                    {rate === "NaN" ? "-" : `${rate}%`}
                                                                </div>
                                                            );
                                                        })}
                                                        <Tooltip title={product.explanation} aria-label={product.explanation}>
                                                            <IconButton aria-label="info" style={{ marginLeft: 10 }}>
                                                                <Help style={{ color: theme.palette.secondary.main }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                )}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color={"secondary"}>
                            cancel
                        </Button>
                        <Button onClick={this.handleNewProductSelection} variant={"contained"} color={"secondary"}>
                            Apply
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default withTheme()(ProductSelector);
