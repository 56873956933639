import React, { Component, Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
    AppBar,
    Toolbar,
    Typography,
    Drawer,
    IconButton,
    ListItem,
    List,
    Divider,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Avatar,
    Menu,
    MenuItem,
    Button
} from "@material-ui/core";
import { Send, ContactSupport, History, QuestionAnswer, CompareArrows, Home, Dashboard, Close } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import "./PageWrapperStyles.css";

class PageWrapper extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        shouldIncludeSideBar: PropTypes.bool,
        contentFullHeight: PropTypes.bool,
        history: PropTypes.object.isRequired,
        authContext: PropTypes.object.isRequired,
        sideMenuContext: PropTypes.object.isRequired
    };

    static defaultProps = {
        shouldIncludeSideBar: false,
        contentFullHeight: false
    };

    constructor(props) {
        super(props);

        this.state = {
            appBarAnchorEl: null,
            currentPage: ""
        };
    }

    componentDidMount() {
        
    }

    matchCurrentPage = page => {
        return window.location.href.indexOf(page) > -1 ? "current-page" : null;
    };

    handleOpenUserMenu = event => {
        this.setState({ appBarAnchorEl: event.currentTarget });
    };

    handleCloseUserMenu = () => {
        this.setState({ appBarAnchorEl: null });
    };

    redirect = route => {
        if (!window.location.href.indexOf(route) > -1) {
            this.props.history.push(`/${route}`);
        }
    };

    render() {
        const { classes, children, title, shouldIncludeSideBar, contentFullHeight, authContext, sideMenuContext } = this.props;

        const { appBarAnchorEl } = this.state;
        return (
            <div className={"root"}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: sideMenuContext.isSideMenuOpen
                    })}
                >
                    <Toolbar disableGutters={true}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginLeft: shouldIncludeSideBar ? null : 16
                                }}
                            >
                                {shouldIncludeSideBar ? (
                                    <Tooltip
                                        title={sideMenuContext.isSideMenuOpen ? "Close menu" : "Open menu"}
                                        aria-label={sideMenuContext.isSideMenuOpen ? "Close" : "Open"}
                                    >
                                        <IconButton
                                            color="inherit"
                                            aria-label="Open drawer"
                                            onClick={sideMenuContext.toggleDrawer}
                                            className={classes.menuButton}
                                        >
                                            {sideMenuContext.isSideMenuOpen ? <Close /> : <MenuIcon />}
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    undefined
                                )}

                                <Typography variant="h6" color="inherit" noWrap>
                                    {title}
                                </Typography>
                            </div>
                            <div>
                                {authContext.user !== null ? (
                                    <Fragment>
                                        <Button
                                            aria-owns={appBarAnchorEl ? "simple-menu" : undefined}
                                            aria-haspopup="true"
                                            onClick={this.handleOpenUserMenu}
                                        >
                                            {authContext.user.photoURL === null ? (
                                                <Avatar style={{ backgroundColor: this.props.theme.palette.secondary.main }}>
                                                    {authContext.user.displayName[0]}
                                                </Avatar>
                                            ) : (
                                                <Avatar alt={authContext.user.displayName} src={authContext.user.photoURL} />
                                            )}

                                            <Typography style={{ color: "#fff", marginLeft: 10 }} variant={"body2"}>
                                                {authContext.user.displayName}
                                            </Typography>
                                        </Button>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={appBarAnchorEl}
                                            open={Boolean(appBarAnchorEl)}
                                            onClose={this.handleCloseUserMenu}
                                        >
                                            <MenuItem onClick={this.handleCloseUserMenu}>Profile</MenuItem>
                                            <MenuItem onClick={authContext.signOut}>Logout</MenuItem>
                                        </Menu>
                                    </Fragment>
                                ) : (
                                    undefined
                                )}
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                {shouldIncludeSideBar ? (
                    <Drawer
                        variant="permanent"
                        className={classNames(classes.drawer, {
                            [classes.drawerOpen]: sideMenuContext.isSideMenuOpen,
                            [classes.drawerClose]: !sideMenuContext.isSideMenuOpen
                        })}
                        classes={{
                            paper: classNames({
                                [classes.drawerOpen]: sideMenuContext.isSideMenuOpen,
                                [classes.drawerClose]: !sideMenuContext.isSideMenuOpen
                            })
                        }}
                        open={sideMenuContext.isSideMenuOpen}
                        onClose={this.handleDrawerClose}
                    >
                        <div className={classes.toolbar} />
                        <Divider />
                        <List>
                            <ListItem
                                button
                                className={this.matchCurrentPage("home")}
                                onClick={() => {
                                    this.redirect("home");
                                }}
                            >
                                <ListItemIcon>
                                    <Home />
                                </ListItemIcon>
                                <ListItemText primary={"Home"} />
                            </ListItem>
                            <ListItem
                                button
                                className={this.matchCurrentPage("dashboard")}
                                onClick={() => {
                                    this.redirect("dashboard");
                                }}
                            >
                                <ListItemIcon>
                                    <Dashboard />
                                </ListItemIcon>
                                <ListItemText primary={"Dashboard"} />
                            </ListItem>
                            <ListItem
                                button
                                className={this.matchCurrentPage("history")}
                                onClick={() => {
                                    this.redirect("history");
                                }}
                            >
                                <ListItemIcon>
                                    <History />
                                </ListItemIcon>
                                <ListItemText primary={"History"} />
                            </ListItem>
                            <ListItem button className={this.matchCurrentPage("compare")}>
                                <ListItemIcon>
                                    <CompareArrows />
                                </ListItemIcon>
                                <ListItemText primary={"Compare"} />
                            </ListItem>
                            <ListItem button className={this.matchCurrentPage("mail")}>
                                <ListItemIcon>
                                    <Send />
                                </ListItemIcon>
                                <ListItemText primary={"Mail"} />
                            </ListItem>
                            <ListItem button className={this.matchCurrentPage("contact")}>
                                <ListItemIcon>
                                    <ContactSupport />
                                </ListItemIcon>
                                <ListItemText primary={"Contact"} />
                            </ListItem>
                            <ListItem button className={this.matchCurrentPage("faq")}>
                                <ListItemIcon>
                                    <QuestionAnswer />
                                </ListItemIcon>
                                <ListItemText primary={"FAQ"} />
                            </ListItem>
                        </List>
                    </Drawer>
                ) : (
                    undefined
                )}

                <div
                    className={classNames(classes.content, {
                        [classes.contentSideMenuOpen]: sideMenuContext.isSideMenuOpen
                    })}
                    style={{ width: shouldIncludeSideBar ? null : "100%" }}
                >
                    <div className={classes.toolbar} />
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", height: contentFullHeight ? "100%" : null }}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

const drawerWidthOpen = 200;
const drawerWidthClose = 57;

const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    menuButton: {
        marginLeft: 4,
        marginRight: 4
    },
    drawer: {
        width: drawerWidthOpen,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: drawerWidthOpen,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: 0,
        [theme.breakpoints.up("sm")]: {
            width: drawerWidthClose
        }
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        ...theme.mixins.toolbar
    },
    content: {
        width: `calc(100% - ${drawerWidthClose}px)`,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    contentSideMenuOpen: {
        width: `calc(100% - ${drawerWidthOpen}px)`,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    }
});

export default withStyles(styles, { withTheme: true })(PageWrapper);
