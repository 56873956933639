import React, { Component } from "react";
import Router from "./Router/Router";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "promise-polyfill/src/polyfill";
import "./Icons/style.css";
import "./App.css";

class App extends Component {
    state = {
        theme: createMuiTheme({
            typography: {
                useNextVariants: true
            },
            palette: {
                primary: {
                    main: "#303f9f"
                },
                secondary: {
                    main: "#e05d22"
                },
                type: "light"
            },
            overrides: {
                MuiOutlinedInput: {
                    // Name of the component ⚛️ / style sheet
                    adornedEnd: {
                        paddingRight: 0
                    }
                }
            }
        })
    };

    render() {
        const { theme } = this.state;

        return (
            <MuiThemeProvider theme={theme}>
                <Router />
            </MuiThemeProvider>
        );
    }
}

export default App;
