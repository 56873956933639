import React from "react";
import logo from "../../logo.svg";

export default function Dashboard() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>404</p>
            </header>
        </div>
    );
}
