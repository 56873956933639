import React, { Component } from "react";
import debounce from "debounce";
import PropTypes from "prop-types";
import { CircularProgress, Paper, Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { XAxis, BarChart, Bar, LabelList, Legend, Tooltip, Area, YAxis, CartesianGrid, AreaChart } from "recharts";
import ChartScrollButton from "./../ChartScrollButton/ChartScrollButton";
import "./ChartContainerStyles.css";

const CustomTooltip = ({ active, payload }) => {
    if (active) {
        return (
            <Paper style={{ padding: "5px 10px" }}>
                <p className="label">&euro; {payload[0].value}</p>
            </Paper>
        );
    }

    return null;
};

class ChartContainer extends Component {
    static propTypes = {
        sideMenuContext: PropTypes.object.isRequired,
        chartType: PropTypes.oneOf(["BarChart", "AreaChart"]),
        chartData: PropTypes.array,
        isChartDataLoading: PropTypes.bool.isRequired,
        totals: PropTypes.array,
        totalsHeader: PropTypes.string,
        isTotalValueWholeNumber: PropTypes.bool
    };

    static defaultProps = {
        chartData: [],
        isChartDataLoading: false,
        chartType: "BarChart",
        isTotalValueWholeNumber: true
    };

    constructor(props) {
        super(props);

        this.chartDomRef = React.createRef();

        this.totalsRef = React.createRef();

        this.state = {
            chartWidth: this.calculateWidth(),
            chartHeight: props.chartType === "BarChart" ? 150 : 200,
            shouldShowScrollXAxisButton: this.shouldShowScrollXAxisButton()
        };

        this.updateChart = this._updateChartDimentions.bind(this);

        this.debouncedUpdate = debounce(this.updateChart, 300);
    }

    componentDidMount() {
        window.addEventListener("resize", this.debouncedUpdate, false);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isChartDataLoading, chartData } = this.props;
        const { chartWidth } = this.state;

        return (
            chartWidth !== nextState.chartWidth || isChartDataLoading !== nextProps.isChartDataLoading || chartData !== nextProps.chartData
        );
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.debouncedUpdate, false);
    }

    _updateChartDimentions() {
        this.setState({ chartWidth: this.calculateWidth(), shouldShowScrollXAxisButton: this.shouldShowScrollXAxisButton() });
    }

    calculateWidth = () => {
        const { sideMenuContext } = this.props;

        const width = document.body.clientWidth < 1600 ? 1500 : document.body.clientWidth - sideMenuContext.drawerWidthClose - 60;

        return width;
    };

    shouldShowScrollXAxisButton = () => {
        const { totals } = this.props;

        const shouldShowScrollXAxisButton = typeof totals !== "undefined" ? true : false;

        const shouldShowScrollXAxisButtonDependingOnWidth = document.body.clientWidth < 1600 ? true : false;

        return shouldShowScrollXAxisButton || shouldShowScrollXAxisButtonDependingOnWidth;
    };

    format = (number, lengthOfDecimal, lengthOfWholePart, sectionsDelimiter, decimalDelimiter) => {
        const re = "\\d(?=(\\d{" + (lengthOfWholePart || 3) + "})+" + (lengthOfDecimal > 0 ? "\\D" : "$") + ")";
        
        const num = parseFloat(number).toFixed(Math.max(0, ~~lengthOfDecimal));

        return (decimalDelimiter ? num.replace(".", decimalDelimiter) : num).replace(
            new RegExp(re, "g"),
            "$&" + (sectionsDelimiter || ",")
        );
    };

    render() {
        const { chartData, isChartDataLoading, theme, chartType, totals, totalsHeader, isTotalValueWholeNumber } = this.props;
        const { chartWidth, chartHeight, shouldShowScrollXAxisButton } = this.state;

        return isChartDataLoading ? (
            <Paper className={"chart-wrapper chart-loader"}>
                <CircularProgress />
            </Paper>
        ) : chartData.length > 1 ? (
            <div className={"chart-container"}>
                <Paper className={"chart-wrapper"} style={{ width: "100%", margin: 0 }}>
                    <div className={"chart"} ref={this.chartDomRef}>
                        {chartType === "BarChart" ? (
                            <BarChart
                                width={chartWidth}
                                height={chartHeight}
                                data={chartData}
                                margin={{ top: 20, right: 20, bottom: 0, left: 0 }}
                            >
                                <XAxis dataKey="name" interval={"preserveStartEnd"} />
                                <Legend className={"chart-legend"} />
                                <Bar
                                    name={chartData.length > 1 ? chartData[0].label : ""}
                                    dataKey="value"
                                    fill={theme.palette.primary.main}
                                >
                                    <LabelList dataKey="value" position="top" />
                                </Bar>
                            </BarChart>
                        ) : (
                            <AreaChart
                                width={chartWidth}
                                height={chartHeight}
                                data={chartData}
                                margin={{ top: 20, right: 20, bottom: 0, left: 0 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend className={"chart-legend"} />
                                <Area
                                    type="monotone"
                                    name={chartData.length > 1 ? chartData[0].label : ""}
                                    dataKey="value"
                                    stroke={theme.palette.primary.main}
                                    fill={theme.palette.primary.main}
                                />
                            </AreaChart>
                        )}
                    </div>
                    {shouldShowScrollXAxisButton ? <ChartScrollButton chartDomRef={this.chartDomRef} /> : null}
                </Paper>
                {typeof totals !== "undefined" ? (
                    <div className={"totals-container"} ref={this.totalsRef}>
                        <Paper className={"totals-paper"}>
                            <div className={"totals-header"}>
                                <Typography variant={"h1"}>{totalsHeader}</Typography>
                            </div>
                            <div className={"totals-item"}>
                                <Typography variant={"h2"}>IFP</Typography>
                                <Typography>
                                    {isTotalValueWholeNumber
                                        ? `€ ${this.format(totals[0], 0, 3, ".", ",")}`
                                        : `${this.format(totals[0], 2, 3, ".", ",")} %`}
                                </Typography>
                            </div>
                            <div className={"totals-item"}>
                                <Typography variant={"h2"}>Duration</Typography>
                                <Typography>
                                    {isTotalValueWholeNumber
                                        ? `€ ${this.format(totals[1], 0, 3, ".", ",")}`
                                        : `${this.format(totals[1], 2, 3, ".", ",")} %`}
                                </Typography>
                            </div>
                        </Paper>
                    </div>
                ) : (
                    undefined
                )}
            </div>
        ) : null;
    }
}

export default withTheme()(ChartContainer);
