import React, { Fragment, Component } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Home from "../Views/Home/Home";
import Dashboard from "../Views/Dashboard/Dashboard";
import NotFound from "../Views/404/404";
import SignIn from "../Views/SignIn/SignIn";
import SignUp from "../Views/SignUp/SignUp";
import ResetPassword from "../Views/ResetPassword/ResetPassword";
import EmailSend from "../Views/EmailSend/EmailSend";
import { InputContextComponent, InputContext } from "./../Contexts/InputContext";
import { AuthContextComponent, AuthContext } from "../Contexts/AuthContext";
import { LoggerContextComponent, LoggerContext } from "../Contexts/LoggerContext";
import { SideMenuContextComponent, SideMenuContext } from "../Contexts/SideMenuContext";
import History from "../Views/History/History";

class RouterComponent extends Component {
    render() {
        return (
            <SideMenuContextComponent>
                <SideMenuContext.Consumer>
                    {sideMenuContext => {
                        return (
                            <AuthContextComponent>
                                <AuthContext.Consumer>
                                    {authContext => {
                                        return !authContext.isAuthStateChanged ? (
                                            <div />
                                        ) : (
                                            <Router>
                                                <Switch>
                                                    <Route
                                                        path="/"
                                                        exact
                                                        render={props => {
                                                            return (
                                                                <Home
                                                                    {...props}
                                                                    authContext={authContext}
                                                                    sideMenuContext={sideMenuContext}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {authContext.isUserAuthenticated && authContext.token !== null ? (
                                                        <InputContextComponent auth={authContext}>
                                                            <InputContext.Consumer>
                                                                {inputContext => {
                                                                    return inputContext.currentProduct.value !== null ? (
                                                                        <LoggerContextComponent auth={authContext} input={inputContext}>
                                                                            <LoggerContext.Consumer>
                                                                                {loggerContext => {
                                                                                    return (
                                                                                        <Switch>
                                                                                            <Route
                                                                                                path="/dashboard"
                                                                                                exact
                                                                                                render={props => {
                                                                                                    return (
                                                                                                        <Dashboard
                                                                                                            {...props}
                                                                                                            authContext={authContext}
                                                                                                            inputContext={inputContext}
                                                                                                            logContext={loggerContext}
                                                                                                            sideMenuContext={
                                                                                                                sideMenuContext
                                                                                                            }
                                                                                                        />
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                            <Route
                                                                                                path="/history"
                                                                                                exact
                                                                                                render={props => {
                                                                                                    return (
                                                                                                        <History
                                                                                                            {...props}
                                                                                                            authContext={authContext}
                                                                                                            inputContext={inputContext}
                                                                                                            logContext={loggerContext}
                                                                                                            sideMenuContext={
                                                                                                                sideMenuContext
                                                                                                            }
                                                                                                        />
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </Switch>
                                                                                    );
                                                                                }}
                                                                            </LoggerContext.Consumer>
                                                                        </LoggerContextComponent>
                                                                    ) : (
                                                                        <Fragment />
                                                                    );
                                                                }}
                                                            </InputContext.Consumer>
                                                        </InputContextComponent>
                                                    ) : (
                                                        <Switch>
                                                            <Route path="/login" exact component={SignIn} />
                                                            <Route path="/register" exact component={SignUp} />
                                                            <Route path="/reset" exact component={ResetPassword} />
                                                            <Route path="/send" exact component={EmailSend} />
                                                            <Redirect to={"/login"} />
                                                        </Switch>
                                                    )}
                                                    <Route path="/404" exact component={NotFound} />
                                                    <Redirect to={"/404"} />
                                                </Switch>
                                            </Router>
                                        );
                                    }}
                                </AuthContext.Consumer>
                            </AuthContextComponent>
                        );
                    }}
                </SideMenuContext.Consumer>
            </SideMenuContextComponent>
        );
    }
}

export default RouterComponent;
