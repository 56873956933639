import React from "react";
import PropTypes from "prop-types";
import { api } from "./../services";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

export const LoggerContext = React.createContext();

export class LoggerContextComponent extends React.Component {
    static contextType = LoggerContext;

    static propTypes = {
        input: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            logs: [],
            areLogsLoaded: false,

            //id of the last item returned fromt he initial response (if the response includes items with id 1, 2 and 3 the value will be 3)
            lastLogId: null,

            //id of the first item returned from the initial response (if the response includes items with id 1, 2 and 3 the value will be 1)
            firstId: null,

            //used to determine if its needed to sync the logs
            lastCreatedLogId: null,

            log: null,

            //back-end requests
            createLog: this.requestCreateLog,
            getLog: this.requestGetLog,
            getLogs: this.requestGetLogs,
            loadOlderLogs: this.requestOlderLogs,
            unloadLog: this.unloadLog
        };
    }

    requestCreateLog = callback => {
        const { auth, input } = this.props;

        axios({
            method: "post",
            url: `${api.url}/log`,
            data: {
                uid: auth.user.uid,
                mortgage_amount: input.mortgageAmount.value,
                value_house: input.valueHouse.value,
                number_of_parts: input.parts.parts.length,
                parts: JSON.stringify(input.parts.parts),
                number_of_incomes: input.applicants.incomes.length,
                incomes: JSON.stringify(input.applicants.incomes),
                start_date: input.startDate.value,
                force_no_nhg: input.forceNoNhg.value,
                transfer_tax: input.transferTax.value,
                notary_deed_of_transfer: input.notaryDeedOfTransfer.value,
                bank_guarantee: input.bankGuarantee.value,
                architectural_inspection: input.architecturalInspection.value,
                notary_mortgage: input.notaryMortgage.value,
                evaluation: input.valuation.value,
                mortgage_advice: input.mortgageAdvice.value,
                mortgage_application: input.mortgageApplication.value,
                weighted_interest_rate: parseFloat(input.interestRate.value),
                interest_rate_key: input.interestRateKey,
                ltv: input.ltv.value,
                average_net_payment_array: JSON.stringify(input.netPaymentData),
                average_gross_payment_array: JSON.stringify(input.grossPaymentData),
                average_weighted_interest_rate_array: JSON.stringify(input.averageInterestRateData),
                mortgage_amount_array: JSON.stringify(input.mortgageAmountData),

                is_nhg_possible: input.isNhgPossible.value,
                required_mortgage_amount: input.requiredMortgageAmount.value,
                required_savings: input.requiredSavings.value,
                nhg_costs: input.nhgCosts.value,
                current_product: JSON.stringify(input.currentProduct.value),

                max_mortgage_from_income: input.maxMortgageAmountIncome.value,

                net_payment_ifp_total: input.totalNetCalculations[0],
                gross_payment_ifp_total: input.totalGrossCalculations[0],
                average_weighted_interest_rate_ifp_total: parseFloat(input.averageInterestRates[0]),
                interest_mortgage_amount_ifp_total: input.totalInterestMortgageAmount[0],

                net_payment_duration_total: input.totalNetCalculations[1],
                gross_payment_duration_total: input.totalGrossCalculations[1],
                average_weighted_interest_rate_duration_total: parseFloat(input.averageInterestRates[1]),
                interest_mortgage_amount_duration_total: input.totalInterestMortgageAmount[1]
            },
            headers: { Authorization: `Bearer ${auth.token}`, "Content-Type": "application/json" }
        })
            .then(response => {
                const res = response.data.data;
                
                this.setState({ lastCreatedLogId: res.id }, () => {
                    if (typeof callback !== "undefined") {
                        callback();
                    }
                });
            })
            .catch(error => {
                
            });
    };

    requestGetLog = (id, callback) => {
        const { auth } = this.props;

        const requestURL = `${api.url}/log/${id}`;

        

        axios({
            method: "get",
            url: requestURL,
            headers: { Authorization: `Bearer ${auth.token}` }
        })
            .then(response => {
                const res = response.data.data;

                
                this.setState({ log: res }, () => {
                    if (typeof callback !== "undefined") {
                        callback();
                    }
                });
            })
            .catch(error => {
                
            });
    };

    requestGetLogs = callback => {
        const { auth } = this.props;
        const { lastLogId, lastCreatedLogId, areLogsLoaded } = this.state;

        const requestURL = `${api.url}/log?uid=${auth.user.uid}${lastLogId !== null ? `&last_log_id=${lastLogId}` : ""}`;

        
        

        if (!areLogsLoaded || lastLogId !== lastCreatedLogId) {
            axios({
                method: "get",
                url: requestURL,
                headers: { Authorization: `Bearer ${auth.token}` }
            })
                .then(response => {
                    const res = response.data.data;

                    

                    const { logs } = this.state;

                    const newLogs = _.cloneDeep(logs);

                    if (!areLogsLoaded || newLogs.length === 0) {
                        
                        res.logs.forEach(log => {
                            const date = new Date(log.mils);

                            let formatedDateFromLog = moment(log.mils, "x").format("dddd, MMM DD, YYYY");

                            if (newLogs.length > 0 && formatedDateFromLog === newLogs[newLogs.length - 1].formatedDate) {
                                newLogs[newLogs.length - 1]["logs"].push(log);
                            } else {
                                newLogs.push({ formatedDate: formatedDateFromLog, date: date, logs: [log] });
                            }
                        });
                        

                        this.setState(
                            {
                                logs: newLogs,
                                lastLogId: res.last_id,
                                lastCreatedLogId: res.last_id,
                                areLogsLoaded: true,
                                firstId: res.first_id
                            },
                            () => {
                                if (typeof callback !== "undefined") {
                                    callback();
                                }
                            }
                        );
                    } else {
                        

                        res.logs.forEach(log => {
                            const date = new Date(log.mils);

                            let formatedDateFromLog = moment(log.mils, "x").format("dddd, MMM DD, YYYY");

                            if (formatedDateFromLog === newLogs[0].formatedDate) {
                                newLogs[0]["logs"].unshift(log);
                            } else {
                                newLogs.unshift({ formatedDate: formatedDateFromLog, date: date, logs: [log] });
                            }
                        });
                        

                        this.setState(
                            {
                                logs: newLogs,
                                lastLogId: res.last_id,
                                lastCreatedLogId: res.last_id,
                                areLogsLoaded: true
                            },
                            () => {
                                if (typeof callback !== "undefined") {
                                    callback();
                                }
                            }
                        );
                    }
                })
                .catch(error => {
                    
                });
        }
    };

    requestOlderLogs = callback => {
        const { auth } = this.props;
        const { firstId } = this.state;

        const requestURL = `${api.url}/log?uid=${auth.user.uid}&first_log_id=${firstId}`;

        

        if (firstId !== null && firstId > 1) {
            axios({
                method: "get",
                url: requestURL,
                headers: { Authorization: `Bearer ${auth.token}` }
            })
                .then(response => {
                    const res = response.data.data;

                    

                    const { logs } = this.state;

                    const newLogs = _.cloneDeep(logs);

                    
                    res.logs.forEach(log => {
                        const date = new Date(log.mils);

                        let formatedDateFromLog = moment(log.mils, "x").format("dddd, MMM DD, YYYY");

                        if (formatedDateFromLog === newLogs[newLogs.length - 1].formatedDate) {
                            newLogs[newLogs.length - 1]["logs"].push(log);
                        } else {
                            newLogs.push({ formatedDate: formatedDateFromLog, date: date, logs: [log] });
                        }
                    });

                    this.setState(
                        {
                            logs: newLogs,
                            firstId: res.first_id
                        },
                        () => {
                            if (typeof callback !== "undefined") {
                                callback();
                            }
                        }
                    );
                })
                .catch(error => {
                    
                });
        }
    };

    unloadLog = callback => {
        this.setState({ log: null }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    /**
     * everything wrapped in this context will have access to the user object passed from the state
     */
    render() {
        const { children } = this.props;

        const state = this.state;

        return <LoggerContext.Provider value={state}>{children}</LoggerContext.Provider>;
    }
}
