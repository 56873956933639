import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { OutlinedInput, FormControl, MenuItem, Select, InputLabel, FormHelperText } from "@material-ui/core";
import "./SelectStyles.css";

function CustomSelect(props) {
    const [labelWidth, setLabelWidth] = useState(0);
    const inputLabelRef = useRef(null);

    useEffect(() => {
        setLabelWidth(ReactDOM.findDOMNode(inputLabelRef.current).offsetWidth);
    });

    return (
        <FormControl className={"select-field"} style={{ width: "100%" }} variant="outlined" error={props.error}>
            <InputLabel ref={inputLabelRef} htmlFor={props.id}>
                {props.label}
            </InputLabel>
            <Select
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                // style={{ width: (labelWidth + 40).toString() + "px" }}
                style={{ width: "100%" }}
                input={<OutlinedInput labelWidth={labelWidth} name={props.name} id={props.id} />}
            >
                {props.options.map((option, key) => {
                    return (
                        <MenuItem key={key} value={option.value}>
                            {option.name}
                        </MenuItem>
                    );
                })}
            </Select>
            {props.errors === null ? (
                props.error ? (
                    <FormHelperText>{props.defaultErrorMessage}</FormHelperText>
                ) : (
                    undefined
                )
            ) : props.error ? (
                <FormHelperText>{props.errors[props.error] ? props.errors[props.error] : props.defaultErrorMessage}</FormHelperText>
            ) : (
                undefined
            )}
        </FormControl>
    );
}

CustomSelect.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        }).isRequired
    ).isRequired,
    error: PropTypes.bool,
    errors: function(props) {
        if (props.errors !== undefined) {
            for (const value in props.errors) {
                if (typeof props.errors[value] !== "string") {
                    throw new Error(
                        `Invalid value for prop 'errors.Object' of type '${typeof props.errors[
                            value
                        ]}' supplied to 'TextField', expected 'string'.`
                    );
                }
            }
        }
    },
    defaultErrorMessage: PropTypes.string
};

CustomSelect.defaultProps = {
    error: false,
    errors: null,
    defaultErrorMessage: "Please fill in."
};

export default CustomSelect;
