import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormHelperText, TextField, InputAdornment } from "@material-ui/core";
import "./TextFieldStyles.css";

function CustomTextField(props) {
    return (
        <FormControl style={{ width: "100%" }} className={"input-field"} error={props.error ? true : false}>
            <TextField
                id={props.id}
                error={props.error ? true : false}
                value={props.value}
                variant={"outlined"}
                onChange={props.onChange}
                onClick={props.onClick}
                name={props.name}
                style={props.style}
                type={props.type}
                className={props.className}
                disabled={props.disabled}
                inputRef={props.inputRef}
                label={props.label}
                InputProps={
                    props.prefix || props.postfix
                        ? props.prefix
                            ? {
                                  startAdornment: (
                                      <InputAdornment position="start" className={"input-adornment"}>
                                          {props.prefix}
                                      </InputAdornment>
                                  )
                              }
                            : {
                                  endAdornment: (
                                      <InputAdornment position="start" className={"input-adornment"}>
                                          {props.postfix}
                                      </InputAdornment>
                                  )
                              }
                        : undefined
                }
            />
            {props.errors === null ? (
                props.error ? (
                    <FormHelperText>{props.defaultErrorMessage}</FormHelperText>
                ) : (
                    undefined
                )
            ) : props.error ? (
                <FormHelperText>{props.errors[props.error] ? props.errors[props.error] : props.defaultErrorMessage}</FormHelperText>
            ) : (
                undefined
            )}
        </FormControl>
    );
}

CustomTextField.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["text", "number", "email", "password", "tel", "date"]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    errors: function(props) {
        if (props.errors !== undefined) {
            for (const value in props.errors) {
                if (typeof props.errors[value] !== "string") {
                    throw new Error(
                        `Invalid value for prop 'errors.Object' of type '${typeof props.errors[
                            value
                        ]}' supplied to 'TextField', expected 'string'.`
                    );
                }
            }
        }
    },
    defaultErrorMessage: PropTypes.string,
    disabled: PropTypes.bool
};

CustomTextField.defaultProps = {
    error: false,
    errors: null,
    defaultErrorMessage: "Please fill in.",
    type: "text",
    disabled: false
};

export default CustomTextField;
