import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { Typography, TextField, Button, FormControl, FormHelperText, CircularProgress, Card, CardContent } from "@material-ui/core";
import "./SignUpStyles.css";

export default class SignUp extends React.Component {
    state = {
        email: "",
        password: "",
        firstName: "",
        confirmPassword: "",
        errors: {
            email: false,
            password: false,
            firstName: false,
            confirmPassword: false
        },
        emailErrorMessage: "",
        isLoading: false
    };

    onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    signUp = () => {
        const { email, password, confirmPassword, firstName, errors } = this.state;

        this.setState({ isLoading: true });

        
        if (firstName.length === 0) {
            this.setState({ errors: { ...errors, firstName: true }, isLoading: false });
        } else {
            this.setState({ errors: { ...errors, firstName: false } });
        }

        //checking if the 2 passwords match
        if (confirmPassword !== password) {
            this.setState({ errors: { ...errors, confirmPassword: true }, isLoading: false });
            return;
        } else {
            this.setState({ errors: { ...errors, confirmPassword: false } });
        }

        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(() => {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                    .then(() => {
                        firebase
                            .auth()
                            .currentUser.updateProfile({
                                displayName: firstName
                            })
                            .then(() => {
                                this.props.history.push("/");
                            });
                    })
                    .catch(() => {
                        this.props.history.push("/login");
                    });
            })
            .catch(error => {
                this.setState({
                    errors: {
                        ...errors,
                        email: error.code === "auth/invalid-email" || error.code === "auth/email-already-in-use" ? true : false,
                        password: error.code === "auth/weak-password" ? true : false
                    },
                    isLoading: false,
                    emailErrorMessage: error.message
                });
            });
    };

    redirectToLogin = () => {
        this.props.history.push("/login");
    };

    render() {
        const { errors, isLoading, emailErrorMessage } = this.state;

        return (
            <div className={"dom"}>
                <Card className={"card"}>
                    <CardContent className={"card-content"}>
                        <Typography variant={"h2"} style={{ margin: "20px 0" }}>
                            Register
                        </Typography>
                        <FormControl error={errors.firstName} className={"sign-form-input"}>
                            <TextField
                                error={errors.firstName}
                                label="First name"
                                variant={"outlined"}
                                className={"sign-input"}
                                type="text"
                                name="firstName"
                                onChange={this.onInputChange}
                                margin="normal"
                            />
                            {errors.email ? (
                                <FormHelperText className={"error-message"}>The field must not be empty.</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>
                        <FormControl error={errors.email} className={"sign-form-input"}>
                            <TextField
                                error={errors.email}
                                label="Email"
                                variant={"outlined"}
                                className={"sign-input"}
                                type="email"
                                name="email"
                                onChange={this.onInputChange}
                                autoComplete="email"
                                margin="normal"
                            />
                            {errors.email ? <FormHelperText className={"error-message"}>{emailErrorMessage}</FormHelperText> : undefined}
                        </FormControl>

                        <FormControl error={errors.password} className={"sign-form-input"}>
                            <TextField
                                error={errors.password}
                                label="Password"
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="password"
                                onChange={this.onInputChange}
                                autoComplete="current-password"
                                margin="normal"
                            />
                            {errors.password ? (
                                <FormHelperText className={"error-message"}>The password must be 6 characters long or more.</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <FormControl error={errors.confirmPassword} className={"sign-form-input"}>
                            <TextField
                                error={errors.confirmPassword}
                                label="Confirm Password"
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="confirmPassword"
                                onChange={this.onInputChange}
                                autoComplete="current-password"
                                margin="normal"
                            />
                            {errors.confirmPassword ? (
                                <FormHelperText className={"error-message"}>The passwords do not match.</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <Button size="large" color="secondary" variant="contained" className={"sign-up-button"} onClick={this.signUp}>
                            {isLoading ? <CircularProgress className={"loader"} /> : "Sign up"}
                        </Button>

                        <div className={"sign-help"}>
                            <Button variant="text" color="primary" onClick={this.redirectToLogin}>
                                Already have an account?
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
