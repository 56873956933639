import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import Input from "./../MortgageDialog/InputField/InputField";
import { InputContext } from "../../Contexts/InputContext";
import { Add, Remove } from "@material-ui/icons";

class NumberOfPartsAndPartsMortgageAmountDialog extends Component {
    static contextType = InputContext;

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open === true && prevProps.open !== this.props.open) {
            this.context.createStateCheckPoint();
        }
    }

    handleMainAction = () => {
        const { onClose } = this.props;
        //some action

        this.context.currentProduct.setValue(this.context.currentProduct.value.code, () => {
            this.context.requestChartsData(() => {
                this.context.maxMortgageAmountIncome.updateValue(() => {
                    this.props.logContext.createLog();
                });
                onClose();
            });
        });
    };

    handleCancelAction = () => {
        const { onClose } = this.props;

        this.context.rollbackState(() => {
            onClose();
        });
    };

    render() {
        const inputContext = this.context;
        const { open } = this.props;

        return (
            <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
                <DialogTitle>Mortgage Parts</DialogTitle>
                <DialogContent className={"dialog-content"}>
                    <div className={"content-wrapper"}>
                        <div className={"content-side"}>
                            {inputContext.parts.parts.map((part, key) => {
                                return (
                                    <Input
                                        key={key}
                                        id={`part-${key + 1}-mortgage-amount`}
                                        label={`Mortgage amount part ${key + 1}`}
                                        type={"text"}
                                        value={part.mortgageAmount.value}
                                        onChange={event => {
                                            inputContext.parts.setPartMortgageAmount(key, event.target.value);
                                        }}
                                        readOnly={key === 0 ? true : false}
                                        error={part.mortgageAmount.error}
                                        errors={part.mortgageAmount.errors}
                                    />
                                );
                            })}
                            <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10%" }}>
                                {inputContext.parts.parts.length === 2 ? (
                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        onClick={() => {
                                            inputContext.parts.removePart();
                                        }}
                                    >
                                        <Remove /> Remove Part
                                    </Button>
                                ) : (
                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        onClick={() => {
                                            inputContext.parts.addPart();
                                        }}
                                    >
                                        <Add /> Add Part
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className={"content-side"}>
                            “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                            laborum.”
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancelAction} color="secondary">
                        cancel
                    </Button>
                    <Button onClick={this.handleMainAction} color="secondary" variant={"contained"}>
                        apply
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTheme()(NumberOfPartsAndPartsMortgageAmountDialog);
