import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Collapse } from "@material-ui/core";
import "./MortgageDialogStyles.css";
import Input from "./InputField/InputField";
import Radio from "./RadioField/RadioField";
import { InputContext } from "../../Contexts/InputContext";
import Line from "./Line/Line";

class MortgageDialog extends Component {
    static contextType = InputContext;

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open === true && prevProps.open !== this.props.open) {
            this.context.createStateCheckPoint();
        }
    }

    handleMainAction = () => {
        const { onClose } = this.props;
        //some action
        this.context.requestChartsData(() => {
            this.context.maxMortgageAmountIncome.updateValue(() => {
                this.props.logContext.createLog();
            });
            onClose();
        });
    };

    handleCancelAction = () => {
        const { onClose } = this.props;

        this.context.rollbackState(() => {
            onClose();
        });
    };

    handleRadioChange = event => {
        const { includeNHG } = this.state;

        const temp = includeNHG;

        temp.value = event.target.value;

        this.setState({ includeNHG: temp });
    };

    render() {
        const inputContext = this.context;
        const { open } = this.props;

        return (
            <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
                <DialogTitle>Mortgage Amount and house value</DialogTitle>
                <DialogContent className={"dialog-content"}>
                    <div className={"content-wrapper"}>
                        <div className={"content-side"}>
                            <Input
                                id={"purchase-price-house"}
                                label={"Purchase price house"}
                                value={inputContext.purchasePriceHouse.value}
                                onChange={event => {
                                    inputContext.purchasePriceHouse.setValue(event.target.value);
                                }}
                                error={inputContext.purchasePriceHouse.error}
                                errors={inputContext.purchasePriceHouse.errors}
                            />
                            <Input
                                id={"transfer-tax"}
                                label={"Transfer tax"}
                                readOnly={true}
                                value={inputContext.transferTax.value}
                                error={null}
                            />
                            <Input
                                id={"notary-deed-of-transfer"}
                                label={"Notary deed of transfer"}
                                value={inputContext.notaryDeedOfTransfer.value}
                                onChange={event => {
                                    inputContext.notaryDeedOfTransfer.setValue(event.target.value);
                                }}
                                error={inputContext.notaryDeedOfTransfer.error}
                                errors={inputContext.notaryDeedOfTransfer.errors}
                            />
                            <Input
                                id={"bank-guarantee"}
                                label={"Bank guarantee"}
                                value={inputContext.bankGuarantee.value}
                                onChange={event => {
                                    inputContext.bankGuarantee.setValue(event.target.value);
                                }}
                                error={inputContext.bankGuarantee.error}
                                errors={inputContext.bankGuarantee.errors}
                            />
                            <Input
                                id={"architectural-inspection"}
                                label={"Architectural inspection"}
                                value={inputContext.architecturalInspection.value}
                                onChange={event => {
                                    inputContext.architecturalInspection.setValue(event.target.value);
                                }}
                                error={inputContext.architecturalInspection.error}
                                errors={inputContext.architecturalInspection.errors}
                            />
                            <Input
                                id={"notary-mortgage"}
                                label={"Notary mortgage"}
                                value={inputContext.notaryMortgage.value}
                                onChange={event => {
                                    inputContext.notaryMortgage.setValue(event.target.value);
                                }}
                                error={inputContext.notaryMortgage.error}
                                errors={inputContext.notaryMortgage.errors}
                            />
                            <Input
                                id={"valuation"}
                                label={"Valuation"}
                                value={inputContext.valuation.value}
                                onChange={event => {
                                    inputContext.valuation.setValue(event.target.value);
                                }}
                                error={inputContext.valuation.error}
                                errors={inputContext.valuation.errors}
                            />
                            <Input
                                id={"mortgage-advice"}
                                label={"Mortgage advice"}
                                value={inputContext.mortgageAdvice.value}
                                onChange={event => {
                                    inputContext.mortgageAdvice.setValue(event.target.value);
                                }}
                                error={inputContext.mortgageAdvice.error}
                                errors={inputContext.mortgageAdvice.errors}
                            />
                            <Input
                                id={"mortgage-application"}
                                label={"Mortgage application"}
                                value={inputContext.mortgageApplication.value}
                                onChange={event => {
                                    inputContext.mortgageApplication.setValue(event.target.value);
                                }}
                                error={inputContext.mortgageApplication.error}
                                errors={inputContext.mortgageApplication.errors}
                            />
                            {!inputContext.isNhgPossible.value ? (
                                <Input label={"National Mortgage Guarnatee(NHG)?"} type={"text"} value={"No"} readOnly={true} />
                            ) : (
                                <Fragment>
                                    <Radio
                                        onChange={event => {
                                            inputContext.forceNoNhg.setValue(event.target.value === "true");
                                        }}
                                        inputs={inputContext.forceNoNhg.inputs}
                                        value={inputContext.forceNoNhg.value.toString()}
                                        label={"National Mortgage Guarnatee(NHG)?"}
                                        name={"include-nhg"}
                                    />
                                    <Collapse in={!inputContext.forceNoNhg.value} timeout="auto" unmountOnExit>
                                        <Input
                                            id={"nhg"}
                                            label={"National Mortgage Guarnatee"}
                                            value={inputContext.nhgCosts.value}
                                            error={inputContext.nhgCosts.error}
                                            readOnly={true}
                                        />
                                    </Collapse>
                                </Fragment>
                            )}
                            <Line />
                            <Input
                                id={"total-required-amount"}
                                label={"Total required amount"}
                                value={inputContext.requiredMortgageAmount.value}
                                error={null}
                                readOnly={true}
                            />
                            <Input
                                id={"investment-savings"}
                                label={"Investment savings"}
                                value={inputContext.requiredSavings.value}
                                onChange={event => {
                                    inputContext.requiredSavings.setValue(event.target.value);
                                }}
                                error={inputContext.requiredSavings.error}
                                errors={inputContext.requiredSavings.errors}
                            />
                            <Line />
                            <Input
                                id={"mortgage-amount"}
                                label={"Mortgage amount"}
                                value={inputContext.mortgageAmount.value}
                                onChange={event => {
                                    inputContext.mortgageAmount.setValue(event.target.value);
                                }}
                                error={inputContext.mortgageAmount.error}
                                errors={inputContext.mortgageAmount.errors}
                            />
                        </div>
                        <div className={"content-side"}>
                            <Input
                                id={"value-house"}
                                label={"Value house"}
                                value={inputContext.valueHouse.value}
                                onChange={event => {
                                    inputContext.valueHouse.setValue(event.target.value);
                                }}
                                type={"text"}
                                error={inputContext.valueHouse.error}
                                errors={inputContext.valueHouse.errors}
                            />
                            <Input id={"ltv"} label={"LTV"} value={`${inputContext.ltv.value}%`} type={"text"} readOnly={true} />
                            <Input
                                id={"interest-rate"}
                                label={"Interest rate"}
                                value={`${(parseFloat(inputContext.interestRate.value) * 100).toFixed(2)}%`}
                                type={"text"}
                                readOnly={true}
                            />
                            <Input
                                id={"max-mortgage-house"}
                                label={"Maximum mortgage house"}
                                type={"text"}
                                value={inputContext.valueHouse.value}
                                readOnly={true}
                            />
                            <Input
                                id={"max-mortgage-income"}
                                label={"Maximum mortgage income"}
                                value={inputContext.maxMortgageAmountIncome.value}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancelAction} color="secondary">
                        cancel
                    </Button>
                    <Button onClick={this.handleMainAction} color="secondary" variant={"contained"}>
                        apply
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTheme()(MortgageDialog);
