import React, { createContext, Component } from "react";
import getRateKey from "./../rates";
import axios from "axios";
import { api } from "./../services";
import _ from "lodash";

export const InputContext = createContext();

const requestDealy = 300;

export class InputContextComponent extends Component {
    static contextType = InputContext;

    constructor(props) {
        super(props);

        this.debouncedNHGUpdate = _.debounce(() => {
            this.requestNHGDefault();
        }, requestDealy);

        this.debouncedNHGAmountUpdate = _.debounce(() => {
            this.requestNHGWithAmount();
        }, requestDealy);

        this.debouncedNHGSavingsUpdate = _.debounce(() => {
            this.requestNHGWithSavings();
        }, requestDealy);

        this.state = {
            mortgageAmount: {
                value: 290000,
                setValue: this.setMortgageAmount,
                error: null,
                conditions: { min: 0, max: 9999999 },
                errors: {
                    min: "min",
                    max: "max",
                    moreThanMaxMortgageIncome: "moreThanMaxMortgageIncome",
                    moreThanMaxMortgageHouse: "moreThanMaxMortgageHouse"
                }
            },
            valueHouse: {
                value: 290000,
                setValue: this.setValueHouse,
                error: null,
                conditions: { min: 50000, max: 5000000 },
                errors: { min: "min", max: "max", lessThanMortgageAmount: "lessThanMortgageAmount" }
            },
            startDate: { value: `1-${new Date().getMonth() + 2}-${new Date().getFullYear()}`, setValue: this.setStartDate },

            forceNoNhg: {
                value: false,
                setValue: this.setForceNoNhg,
                inputs: [{ value: false, label: "Yes" }, { value: true, label: "No" }]
            },
            isNhgPossible: { value: true, setValue: this.setIsNhgPossible },

            maxMortgageAmountIncome: {
                value: 292905,
                updateValue: this.requestMaxMortgageAmountFromIncome
            },
            requiredMortgageAmount: { value: 262300 },

            requiredSavings: {
                value: 12300,
                setValue: this.setRequiredSavings,
                conditions: { min: 0, max: 99999999 },
                errors: { min: "min", max: "max" }
            },
            purchasePriceHouse: {
                value: 290000,
                setValue: this.setPurchasePriceHouse,
                error: null,
                conditions: { min: 50000, max: 5000000 },
                errors: { min: "min", max: "max" }
            },
            nhgCosts: {
                value: 2250
            },
            transferTax: {
                value: 1000,
                percentage: 0.02
            },
            notaryDeedOfTransfer: {
                value: 600,
                error: null,
                conditions: { min: 100, max: 1000, equal: 0 },
                errors: { min: "min", max: "max" },
                setValue: this.setNotaryDeedOfTransfer
            },
            bankGuarantee: {
                value: 225,
                error: null,
                percentage: 0.009,
                conditions: { min: 225, max: 1000, equal: 0 },
                errors: { min: "min", max: "max" },
                setValue: this.setBankGuarantee
            },
            architecturalInspection: {
                value: 450,
                error: null,
                conditions: { min: 450, max: 1000, equal: 0 },
                errors: { min: "min", max: "max" },
                setValue: this.setArchitecturalInspection
            },
            notaryMortgage: {
                value: 600,
                error: null,
                conditions: { min: 100, max: 1000 },
                errors: { min: "min", max: "max" },
                setValue: this.setNotaryMortgage
            },
            valuation: {
                value: 500,
                error: null,
                conditions: { min: 500, max: 1000, equal: 0 },
                errors: { min: "min", max: "max" },
                setValue: this.setValuation
            },
            mortgageAdvice: {
                value: 1300,
                error: null,
                conditions: { min: 1300, max: 2000, equal: 0 },
                errors: { min: "min", max: "max" },
                setValue: this.setMortgageAdvice
            },
            mortgageApplication: {
                value: 600,
                error: null,
                conditions: { min: 600, max: 2000, equal: 0 },
                errors: { min: "min", max: "max" },
                setValue: this.setMortgageApplication
            },
            interestRate: { value: 0.0167 },
            ltv: { value: 100 },
            interestRateKey: getRateKey(true, 100),

            parts: {
                parts: [
                    {
                        mortgageAmount: {
                            value: 290000,
                            conditions: { min: 5000, max: 99999999 },
                            error: null,
                            errors: { min: "min", max: "max" }
                        },
                        mortgageType: {
                            value: "annuity",
                            requestValue: "annuity",
                            availableOptions: [],
                            options: [
                                { name: "Annuity", value: "annuity" },
                                { name: "Linear", value: "linear" },
                                { name: "Fixed", value: "fixed" }
                            ]
                        },
                        duration: {
                            value: 30,
                            availableOptions: [
                                { label: "15", value: 15 },
                                { label: "16", value: 16 },
                                { label: "17", value: 17 },
                                { label: "18", value: 18 },
                                { label: "19", value: 19 },
                                { label: "20", value: 20 },
                                { label: "21", value: 21 },
                                { label: "22", value: 22 },
                                { label: "23", value: 23 },
                                { label: "24", value: 24 },
                                { label: "25", value: 25 },
                                { label: "26", value: 26 },
                                { label: "27", value: 27 },
                                { label: "28", value: 28 },
                                { label: "29", value: 29 },
                                { label: "30", value: 30 }
                            ]
                        },
                        interestFixedPeriod: {
                            value: 10,
                            requestValue: 10,
                            availableOptions: [],
                            options: [
                                { name: "Variable", value: "variabel" },
                                { name: "1", value: 1 },
                                { name: "2", value: 2 },
                                { name: "3", value: 3 },
                                { name: "5", value: 5 },
                                { name: "6", value: 6 },
                                { name: "7", value: 7 },
                                { name: "10", value: 10 },
                                { name: "12", value: 12 },
                                { name: "15", value: 15 },
                                { name: "20", value: 20 },
                                { name: "25", value: 25 },
                                { name: "30", value: 30 }
                            ]
                        },
                        interestRate: { value: 0.0167 }
                    }
                ],
                addPart: this.addPart,
                removePart: this.removePart,
                setParts: this.setParts,
                setPartMortgageAmount: this.setPartMortgageAmount,
                setPartMortgageType: this.setPartMortgageType,
                setPartMortgageTypeRequest: this.setPartMortgageTypeRequest,
                setPartDuration: this.setPartDuration,
                setPartInterestFixedPeriod: this.setPartInterestFixedPeriod,
                setPartInterestFixedPeriodRequest: this.setPartInterestFixedPeriodRequest,
                setPartInterestRate: this.setPartInterestRate,
                updateParts: this.updateParts,
                updatePartsRates: this.updatePartsRates,
                toString: this.convertPartsToString,
                toStringRequest: this.convertPartsToStringForRequestForProducts
            },

            applicants: {
                incomes: [
                    {
                        value: 30000,
                        error: null,
                        conditions: { min: 5000, max: 1000000 },
                        errors: { min: "min", max: "max" }
                    },
                    {
                        value: 30000,
                        error: null,
                        conditions: { min: 5000, max: 1000000 },
                        errors: { min: "min", max: "max" }
                    }
                ],
                inputs: [{ value: 1, label: "1" }, { value: 2, label: "2" }],
                addApplicant: this.addApplicant,
                removeApplicant: this.removeApplicant,
                setApplicantAmount: this.setApplicantAmount
            },
            currentProduct: { value: null, setValue: this.setCurrentProduct },

            updateRateKeyAndLTV: this.updateRateKeyAndLTV,

            isChartDataLoading: true,

            mortgageAmountData: [],
            grossPaymentData: [],
            netPaymentData: [],
            averageInterestRateData: [],

            totalGrossCalculations: [],
            totalNetCalculations: [],
            averageInterestRates: [],
            totalInterestMortgageAmount: [],

            requestChartsData: this.requestChartsData,
            requestNHG: this.requestNHGDefault,

            rollbackState: this.rollbackState,
            createStateCheckPoint: this.createStateCheckPoint,
            loadStateFromLog: this.loadStateFromLog,

            rateError: false,

            lastState: {}
        };
    }

    componentDidMount() {
        this.setCurrentProduct("EXAMPLE", () => {
            this.requestChartsData();
            this.requestMaxMortgageAmountFromIncome();
        });
    }

    createStateCheckPoint = callback => {
        this.setState({ lastState: _.cloneDeep(this.state) }, () => {
            
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    rollbackState = callback => {
        this.setState(this.state.lastState, () => {
            
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    loadStateFromLog = (object, callback) => {
        const newState = _.cloneDeep(this.state);

        //calculations
        newState.mortgageAmountData = object.calculation.mortgage_amount_array;
        newState.grossPaymentData = object.calculation.average_gross_payment_array;
        newState.netPaymentData = object.calculation.average_net_payment_array;
        newState.averageInterestRateData = object.calculation.average_weighted_interest_rate_array;

        newState.totalGrossCalculations = [object.calculation.gross_payment_ifp_total, object.calculation.gross_payment_duration_total];
        newState.totalNetCalculations = [object.calculation.net_payment_duration_total, object.calculation.net_payment_ifp_total];
        newState.averageInterestRates = [
            object.calculation.average_weighted_interest_rate_duration_total,
            object.calculation.average_weighted_interest_rate_ifp_total
        ];
        newState.totalInterestMortgageAmount = [
            object.calculation.interest_mortgage_amount_duration_total,
            object.calculation.interest_mortgage_amount_ifp_total
        ];
        newState.maxMortgageAmountIncome.value = object.calculation.max_mortgage_from_income;
        newState.isNhgPossible.value = object.input.is_nhg_possible;

        //parts
        object.input.parts.forEach((part, index) => {
            const oldPart = newState.parts.parts[index];
            if (typeof oldPart === "undefined") {
                newState.parts.parts.push(part);
            } else {
                newState.parts.parts[index] = { ...oldPart, ...part };
            }
        });

        //income
        object.input.incomes.forEach((income, index) => {
            const oldIncome = newState.applicants.incomes[index];
            if (typeof oldIncome === "undefined") {
                newState.applicants.incomes.push(income);
            } else {
                newState.applicants.incomes[index] = { ...oldIncome, ...income };
            }
        });

        //input
        newState.valueHouse.value = object.input.value_house;
        newState.mortgageAmount.value = object.input.mortgage_amount;
        newState.ltv.value = parseInt(object.input.ltv);
        newState.forceNoNhg.value = object.input.force_no_nhg;
        newState.startDate.value = object.input.start_date;

        //product
        newState.currentProduct.value = object.current_product.current_product;

        this.setState(newState, () => {
            
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setCurrentProduct = (code, callback) => {
        const { parts, currentProduct } = this.state;

        let requestURL = `${api.url}/product?code=${code}&parts=${parts.toStringRequest()}`;

        if (currentProduct.value === null || code === "EXAMPLE") {
            requestURL = `${api.url}/products/default?parts=${parts.toString()}`;
        }

        

        axios({
            method: "get",
            url: requestURL,
            headers: { Authorization: `Bearer ${this.props.auth.token}` }
        })
            .then(response => {
                const product = response.data.data;

                this.setState({ currentProduct: { ...currentProduct, value: product } }, () => {
                    
                    this.updateParts(() => {
                        this.updateRateKeyAndLTV();
                    });
                    if (typeof callback !== "undefined") {
                        callback();
                    }
                });
            })
            .catch(error => {
                
            });
    };

    requestMaxMortgageAmountFromIncome = callback => {
        const { applicants, parts } = this.state;

        const body = new FormData();

        const number_of_parts = applicants.incomes.length;

        body.append("number_of_applicants", number_of_parts);

        applicants.incomes.forEach((income, index) => {
            body.append(`income_${index + 1}`, income.value);
        });

        body.append("parts", parts.toString());

        body.append("duration", parts.parts[0].duration.value);

        axios({
            method: "post",
            url: `${api.url}/mortgage/max`,
            data: body,
            headers: { Authorization: `Bearer ${this.props.auth.token}` }
        })
            .then(response => {
                const data = response.data.data;

                const validation = this.validateMortgageAmount(this.state.mortgageAmount.value);

                this.setState(
                    {
                        maxMortgageAmountIncome: { ...this.state.maxMortgageAmountIncome, value: data.max_mortgage },
                        mortgageAmount: { ...this.state.mortgageAmount, error: validation }
                    },
                    () => {
                        //validate the fields after the value from the calculations is resolved
                        this.validateMortgageAmountAndValueHouse();
                        
                        if (typeof callback !== "undefined") {
                            callback();
                        }
                    }
                );
            })
            .catch(error => {
                
            });
    };

    requestNHGDefault = callback => {
        const { valueHouse, purchasePriceHouse, isNhgPossible, forceNoNhg } = this.state;

        const nhgForRequest = isNhgPossible.value ? !forceNoNhg.value : false;

        const formData = new FormData();

        formData.append("house_value", valueHouse.value);
        formData.append("house_purchase_value", purchasePriceHouse.value);
        formData.append("apply_nhg", nhgForRequest);

        formData.append("costs_notary_transfer", this.state.notaryDeedOfTransfer.value);
        formData.append("costs_bank_guarantee_minimum", this.state.bankGuarantee.value);
        formData.append("costs_architectural_inspection", this.state.architecturalInspection.value);
        formData.append("costs_notary_mortgage", this.state.notaryMortgage.value);
        formData.append("costs_evaluation", this.state.valuation.value);
        formData.append("costs_mortgage_advice", this.state.mortgageAdvice.value);
        formData.append("costs_mortgage_application", this.state.mortgageApplication.value);

        axios({
            method: "post",
            url: `${api.url}/mortgage/nhg`,
            data: formData,
            headers: { Authorization: `Bearer ${this.props.auth.token}` }
        })
            .then(response => {
                const data = response.data.data;

                this.setState(
                    {
                        mortgageAmount: { ...this.state.mortgageAmount, value: data.mortgage_amount },
                        nhgCosts: { ...this.state.nhgCosts, value: data.nhg_costs },
                        isNhgPossible: { ...this.state.isNhgPossible, value: data.nhg_possible },
                        requiredMortgageAmount: { ...this.state.requiredMortgageAmount, value: data.required_amount },
                        requiredSavings: { ...this.state.requiredSavings, value: data.required_savings }
                    },
                    () => {
                        
                        this.updateRateKeyAndLTV();
                        this.updatePartsAmounts();
                        if (typeof callback !== "undefined") {
                            callback();
                        }
                    }
                );
            })
            .catch(error => {
                
            });
    };

    requestNHGWithAmount = callback => {
        const { mortgageAmount, valueHouse, purchasePriceHouse, isNhgPossible, forceNoNhg } = this.state;

        const nhgForRequest = isNhgPossible.value ? !forceNoNhg.value : false;

        const formData = new FormData();
        formData.append("house_value", valueHouse.value);
        formData.append("house_purchase_value", purchasePriceHouse.value);
        formData.append("apply_nhg", nhgForRequest);

        formData.append("mortgage_amount", mortgageAmount.value);

        formData.append("costs_notary_transfer", this.state.notaryDeedOfTransfer.value);
        formData.append("costs_bank_guarantee_minimum", this.state.bankGuarantee.value);
        formData.append("costs_architectural_inspection", this.state.architecturalInspection.value);
        formData.append("costs_notary_mortgage", this.state.notaryMortgage.value);
        formData.append("costs_evaluation", this.state.valuation.value);
        formData.append("costs_mortgage_advice", this.state.mortgageAdvice.value);
        formData.append("costs_mortgage_application", this.state.mortgageApplication.value);

        axios({
            method: "post",
            url: `${api.url}/mortgage/nhg/amount`,
            data: formData,
            headers: { Authorization: `Bearer ${this.props.auth.token}` }
        })
            .then(response => {
                const data = response.data.data;

                this.setState(
                    {
                        mortgageAmount: { ...this.state.mortgageAmount, value: data.mortgage_amount },
                        nhgCosts: { ...this.state.nhgCosts, value: data.nhg_costs },
                        isNhgPossible: { ...this.state.isNhgPossible, value: data.nhg_possible },
                        requiredMortgageAmount: { ...this.state.requiredMortgageAmount, value: data.required_amount },
                        requiredSavings: { ...this.state.requiredSavings, value: data.required_savings }
                    },
                    () => {
                        

                        this.updateRateKeyAndLTV();
                        this.updatePartsAmounts();

                        if (typeof callback !== "undefined") {
                            callback();
                        }
                    }
                );
            })
            .catch(error => {
                
            });
    };

    requestNHGWithSavings = callback => {
        const { valueHouse, purchasePriceHouse, isNhgPossible, forceNoNhg } = this.state;

        const nhgForRequest = isNhgPossible.value ? !forceNoNhg.value : false;

        const formData = new FormData();
        formData.append("house_value", valueHouse.value);
        formData.append("house_purchase_value", purchasePriceHouse.value);
        formData.append("apply_nhg", nhgForRequest);

        formData.append("savings", this.state.requiredSavings.value);

        formData.append("costs_notary_transfer", this.state.notaryDeedOfTransfer.value);
        formData.append("costs_bank_guarantee_minimum", this.state.bankGuarantee.value);
        formData.append("costs_architectural_inspection", this.state.architecturalInspection.value);
        formData.append("costs_notary_mortgage", this.state.notaryMortgage.value);
        formData.append("costs_evaluation", this.state.valuation.value);
        formData.append("costs_mortgage_advice", this.state.mortgageAdvice.value);
        formData.append("costs_mortgage_application", this.state.mortgageApplication.value);

        axios({
            method: "post",
            url: `${api.url}/mortgage/nhg/savings`,
            data: formData,
            headers: { Authorization: `Bearer ${this.props.auth.token}` }
        })
            .then(response => {
                const data = response.data.data;

                this.setState(
                    {
                        mortgageAmount: { ...this.state.mortgageAmount, value: data.mortgage_amount },
                        nhgCosts: { ...this.state.nhgCosts, value: data.nhg_costs },
                        isNhgPossible: { ...this.state.isNhgPossible, value: data.nhg_possible },
                        requiredMortgageAmount: { ...this.state.requiredMortgageAmount, value: data.required_amount },
                        requiredSavings: { ...this.state.requiredSavings, value: data.required_savings }
                    },
                    () => {
                        
                        this.updateRateKeyAndLTV();
                        this.updatePartsAmounts();
                        if (typeof callback !== "undefined") {
                            callback();
                        }
                    }
                );
            })
            .catch(error => {
                
            });
    };

    requestChartsData = callback => {
        const { valueHouse, mortgageAmount, isNhgPossible, forceNoNhg, parts, applicants, currentProduct, startDate } = this.state;

        // this.setState({ isChartDataLoading: true });

        const nhg = isNhgPossible.value ? !forceNoNhg.value : false;

        const formData = new FormData();

        formData.append("house_value", valueHouse.value);

        applicants.incomes.forEach((income, index) => {
            formData.append(`income_${index + 1}`, income.value);
        });

        formData.append("mortgage_amount", mortgageAmount.value);

        formData.append("include_monthly_calculations", true);

        formData.append("nhg", nhg);

        formData.append("parts", parts.toString());

        if (currentProduct.value.code !== "EXAMPLE") {
            formData.append("product_code", currentProduct.value.code);
        }

        formData.append("start_date", startDate.value);

        axios({
            method: "post",
            url: `${api.url}/mortgage/yearly`,
            data: formData,
            headers: { Authorization: `Bearer ${this.props.auth.token}` }
        })
            .then(response => {
                let grossPayments = [];
                let netPayments = [];
                let mortgageAmount = [];
                let averageInterestRate = [];
                let totalGrossCalculations = [];
                let totalNetCalculations = [];
                let averageInterestRates = [];
                let totalInterestMortgageAmount = [];

                

                const ifp_calcualtions = response.data.data.interest_fixed_period_calculations.calculations;

                const ifp_totals = response.data.data.interest_fixed_period_calculations.totals;

                totalGrossCalculations.push(Math.round(ifp_totals.gross_payment));
                totalNetCalculations.push(Math.round(ifp_totals.net_payment));
                averageInterestRates.push(parseFloat(ifp_totals.average_interest_rate * 100).toFixed(2));
                totalInterestMortgageAmount.push(Math.round(ifp_totals.interest_mortgage_amount));

                ifp_calcualtions.forEach((element, index) => {
                    const year = element.year;

                    grossPayments.push({
                        name: year,
                        value: Math.round(element.average_gross_payment),
                        label: "Average gross payment"
                    });
                    netPayments.push({
                        name: year,
                        value: Math.round(element.average_net_payment),
                        label: "Average net payment"
                    });
                    mortgageAmount.push({
                        name: year,
                        value: Math.round(element.mortgage_amount_start),
                        label: "Mortgage amount at start"
                    });
                    averageInterestRate.push({
                        name: year,
                        value: parseFloat(element.average_interest_rate * 100).toFixed(2),
                        label: "Average weighted interest rate"
                    });
                });

                const duration_calculations = response.data.data.duration_calculations.calculations;
                const duration_totals = response.data.data.duration_calculations.totals;

                totalGrossCalculations.push(Math.round(duration_totals.gross_payment));
                totalNetCalculations.push(Math.round(duration_totals.net_payment));
                averageInterestRates.push(parseFloat(duration_totals.average_interest_rate * 100).toFixed(2));
                totalInterestMortgageAmount.push(Math.round(duration_totals.interest_mortgage_amount));

                duration_calculations.forEach((element, index) => {
                    const year = element.year;

                    // checking if the entry for the year is already in the array from the ifp
                    // calculations (this occurs when the ifp and duration are different)
                    if (index === 0) {
                        const lastEntryGross = grossPayments[grossPayments.length - 1];
                        const lastEntryNet = netPayments[netPayments.length - 1];
                        const lastEntryInterest = averageInterestRate[averageInterestRate.length - 1];

                        if (lastEntryGross.name === year) {
                            const value = Math.round(element.average_gross_payment);
                            const lastEntryValue = lastEntryGross.value;
                            grossPayments[grossPayments.length - 1].value = Math.round((lastEntryValue + value) / 2);
                        }
                        if (lastEntryNet.name === year) {
                            const value = element.average_net_payment;
                            const lastEntryValue = lastEntryNet.value;
                            netPayments[netPayments.length - 1].value = Math.round((lastEntryValue + value) / 2);
                        }
                        if (lastEntryInterest.name === year) {
                            const value = element.average_interest_rate;
                            const lastEntryValue = lastEntryInterest.value;
                            averageInterestRate[averageInterestRate.length - 1].value = parseFloat(
                                (parseFloat(lastEntryValue) + value * 100) / 2
                            ).toFixed(2);
                        }
                        //skipping the mortgage amount since it is not at the begining of the year but at the middle
                    } else {
                        grossPayments.push({
                            name: year,
                            value: Math.round(element.average_gross_payment),
                            label: "Average gross payment"
                        });
                        netPayments.push({
                            name: year,
                            value: Math.round(element.average_net_payment),
                            label: "Average net payment"
                        });
                        mortgageAmount.push({
                            name: year,
                            value: Math.round(element.mortgage_amount_start),
                            label: "Mortgage amount at start"
                        });
                        averageInterestRate.push({
                            name: year,
                            value: parseFloat(element.average_interest_rate * 100).toFixed(2),
                            label: "Average weighted average"
                        });
                    }
                });
                this.setState(
                    {
                        mortgageAmountData: mortgageAmount,
                        grossPaymentData: grossPayments,
                        netPaymentData: netPayments,
                        averageInterestRateData: averageInterestRate,
                        isChartDataLoading: false,
                        totalGrossCalculations,
                        totalNetCalculations,
                        averageInterestRates,
                        totalInterestMortgageAmount
                    },
                    () => {
                        //adas
                        
                        if (typeof callback !== "undefined") {
                            callback();
                        }
                    }
                );
            })
            .catch(error => {
                
            });
    };

    setMortgageAmount = (newValue, callback) => {
        const { mortgageAmount, valueHouse } = this.state;

        const validation = this.validateMortgageAmount(newValue);

        const validationHouse = this.validateMortgageAmount(valueHouse.value);

        this.setState(
            {
                mortgageAmount: { ...mortgageAmount, value: newValue, error: validation },
                valueHouse: { ...valueHouse, error: validationHouse }
            },
            () => {
                this.debouncedNHGAmountUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setValueHouse = (newValue, callback) => {
        const { valueHouse, mortgageAmount } = this.state;

        const validationMortgage = this.validateMortgageAmount(mortgageAmount.value);

        const validation = this.validateValueHouse(newValue);

        this.setState(
            {
                mortgageAmount: { ...mortgageAmount, error: validationMortgage },
                valueHouse: { ...valueHouse, value: newValue, error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setRequiredSavings = (newValue, callback) => {
        const { requiredSavings } = this.state;

        const validation = this.validate(requiredSavings, newValue);

        this.setState({ requiredSavings: { ...requiredSavings, value: newValue, error: validation } }, () => {
            this.debouncedNHGSavingsUpdate();
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setStartDate = (newDay, newMonth, newYear, callback) => {
        const { startDate } = this.state;

        this.setState({ startDate: { ...startDate, value: `${newDay}-${newMonth}-${newYear}` } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPurchasePriceHouse = (newValue, callback) => {
        const { purchasePriceHouse, bankGuarantee, transferTax, valueHouse, mortgageAmount } = this.state;

        const validationMortgage = this.validateMortgageAmount(mortgageAmount.value);

        const validationHouse = this.validateValueHouse(newValue);

        const validation = this.validate(purchasePriceHouse, newValue);

        const transferTaxValue = newValue * transferTax.percentage;

        const bankValue = Math.round(bankGuarantee.percentage * (newValue * 0.1));

        const bankGuaranteeValue = bankGuarantee.conditions.max < bankValue ? bankGuarantee.conditions.max : bankValue;

        this.setState(
            {
                mortgageAmount: { ...mortgageAmount, error: validationMortgage },
                valueHouse: { ...valueHouse, value: newValue, error: validationHouse },
                purchasePriceHouse: { ...purchasePriceHouse, value: newValue, error: validation },
                bankGuarantee: { ...bankGuarantee, value: bankGuaranteeValue },
                transferTax: { ...transferTax, value: transferTaxValue }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setNotaryDeedOfTransfer = (newValue, callback) => {
        const { notaryDeedOfTransfer } = this.state;

        const validation = this.validate(notaryDeedOfTransfer, newValue);

        this.setState(
            {
                notaryDeedOfTransfer: { ...notaryDeedOfTransfer, value: newValue, error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setForceNoNhg = (newValue, callback) => {
        const { forceNoNhg } = this.state;

        this.setState(
            {
                forceNoNhg: { ...forceNoNhg, value: newValue }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setBankGuarantee = (newValue, callback) => {
        const { bankGuarantee } = this.state;

        const validation = this.validate(bankGuarantee, newValue);

        this.setState(
            {
                bankGuarantee: { ...bankGuarantee, value: newValue, error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setArchitecturalInspection = (newValue, callback) => {
        const { architecturalInspection } = this.state;

        const validation = this.validate(architecturalInspection, newValue);

        this.setState(
            {
                architecturalInspection: { ...architecturalInspection, value: newValue, error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setNotaryMortgage = (newValue, callback) => {
        const { notaryMortgage } = this.state;

        const validation = this.validate(notaryMortgage, newValue);

        this.setState(
            {
                notaryMortgage: { ...notaryMortgage, value: newValue, error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setValuation = (newValue, callback) => {
        const { valuation } = this.state;

        const validation = this.validate(valuation, newValue);

        this.setState(
            {
                valuation: { ...valuation, value: newValue, error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setMortgageAdvice = (newValue, callback) => {
        const { mortgageAdvice } = this.state;

        const validation = this.validate(mortgageAdvice, newValue);

        this.setState(
            {
                mortgageAdvice: { ...mortgageAdvice, value: newValue, error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    setMortgageApplication = (newValue, callback) => {
        const { mortgageApplication } = this.state;

        const validation = this.validate(mortgageApplication, newValue);

        this.setState(
            {
                mortgageApplication: { ...mortgageApplication, value: newValue, error: validation }
            },
            () => {
                this.debouncedNHGUpdate();
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    addPart = callback => {
        const { parts } = this.state;

        const newParts = _.cloneDeep(parts);

        const newPart = _.cloneDeep(newParts.parts[0]);

        newPart.mortgageAmount.value = 5000;

        newParts.parts.push(newPart);

        newParts.parts[0].mortgageAmount.value = newParts.parts[0].mortgageAmount.value - 5000;

        this.setState({ parts: newParts }, () => {
            this.updateWeightedAvgInterestRate();
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    removePart = callback => {
        const { parts, mortgageAmount } = this.state;

        const newParts = _.cloneDeep(parts.parts);

        newParts.pop();

        newParts[0].mortgageAmount.value = mortgageAmount.value;

        this.setState({ parts: { ...parts, parts: newParts } }, () => {
            this.updateWeightedAvgInterestRate();
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartMortgageAmount = (index, value, callback) => {
        const { mortgageAmount, parts } = this.state;

        const newParts = _.cloneDeep(parts.parts);

        const part = newParts[index];

        const validation = this.validate(part.mortgageAmount, value);

        part.mortgageAmount.value = value;
        part.mortgageAmount.error = validation;

        let leftOutMortgageAmount = mortgageAmount.value - value;

        for (let partIndex = 0; partIndex < newParts.length; partIndex++) {
            const tempPart = newParts[partIndex];
            if (partIndex !== index && leftOutMortgageAmount > 0) {
                const valueToAssign = tempPart.mortgageAmount.value - leftOutMortgageAmount;

                const tempValidation = this.validate(tempPart.mortgageAmount, value);
                tempPart.mortgageAmount.value -= valueToAssign;
                tempPart.mortgageAmount.error = tempValidation;
                leftOutMortgageAmount -= valueToAssign;
            }
        }

        this.setState({ parts: { ...parts, parts: newParts } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartMortgageType = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, mortgageType: { ...currentPart.mortgageType, value: value, requestValue: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartMortgageTypeRequest = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, mortgageType: { ...currentPart.mortgageType, requestValue: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartDuration = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, duration: { ...currentPart.duration, value: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartInterestFixedPeriod = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, interestFixedPeriod: { ...currentPart.interestFixedPeriod, value: value, requestValue: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartInterestFixedPeriodRequest = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, interestFixedPeriod: { ...currentPart.interestFixedPeriod, requestValue: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setPartInterestRate = (value, index, callback) => {
        const { parts } = this.state;

        const newParts = parts;

        const currentPart = newParts.parts[index];

        const newPart = { ...currentPart, interestRate: { ...currentPart.interestRate, value: value } };

        newParts.parts[index] = newPart;

        this.setState({ parts: newParts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    updateParts = callback => {
        const { parts, currentProduct, interestRateKey } = this.state;

        const tempParts = parts.parts;

        currentProduct.value.parts.forEach((part, index) => {
            tempParts[index].interestRate.value = part.interest_rates[interestRateKey];
            tempParts[index].mortgageType.value = part.mortgage_type;
            tempParts[index].interestFixedPeriod.value = tempParts[index].interestFixedPeriod.requestValue;

            const availablePeriods = [];

            part.available_interest_fixed_periods.forEach((period, index) => {
                if (!isNaN(parseInt(period))) {
                    availablePeriods.push({ name: period, value: parseInt(period), label: period });
                } else {
                    availablePeriods.push({ name: "Variable", value: period, label: "Variable" });
                }
            });

            availablePeriods.sort(function(a, b) {
                if (a.value < b.value) {
                    return -1;
                } else if (a.value > b.value) {
                    return 1;
                }
                return 0;
            });

            tempParts[index].interestFixedPeriod.availableOptions = availablePeriods;

            const availableTypes = [];

            part.available_types.forEach((type, index) => {
                const name = type.charAt(0).toUpperCase() + type.slice(1);
                availableTypes.push({ name: name, value: type, label: name });
            });

            tempParts[index].mortgageType.availableOptions = availableTypes;
        });

        this.setState({ parts: { ...parts, parts: tempParts } }, () => {
            this.updateWeightedAvgInterestRate();
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setParts = (parts, callback) => {
        this.setState({ parts: parts }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    updatePartsRates = callback => {
        const { parts, currentProduct, interestRateKey } = this.state;

        const newParts = _.cloneDeep(parts);

        let hasError = false;
        

        for (let index = 0; index < newParts.parts.length; index++) {
            const currentPart = newParts.parts[index];

            const newRate = currentProduct.value.parts[index].interest_rates[interestRateKey];

            
            if (newRate === null) {
                hasError = true;
            } else {
                currentPart.interestRate.value = newRate;
            }
        }

        this.setState({ parts: newParts, rateError: hasError }, () => {
            
            this.updateWeightedAvgInterestRate();
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    updatePartsAmounts = callback => {
        const { parts, mortgageAmount } = this.state;

        const newParts = _.cloneDeep(parts);

        const partOne = newParts.parts[0];

        const mortgageAmountValue = parseInt(mortgageAmount.value);

        if (newParts.parts.length > 1) {
            const partTwo = newParts.parts[1];

            const total = parseInt(partOne.mortgageAmount.value) + parseInt(partTwo.mortgageAmount.value);

            //if mortgage amount is more
            if (total < mortgageAmountValue) {
                const difference = mortgageAmountValue - total;

                this.setPartMortgageAmount(1, parseInt(partTwo.mortgageAmount.value) + difference);
            } else if (total > mortgageAmountValue) {
                const difference = total - mortgageAmountValue;
                //remove part 2 as the new amount is more
                if (difference + partTwo.mortgageAmount.conditions.min > partTwo.mortgageAmount.value) {
                    this.removePart(() => {
                        this.updateRateKeyAndLTV();
                    });
                } else {
                    this.setPartMortgageAmount(1, parseInt(partTwo.mortgageAmount.value) - difference);
                }
            }
        } else {
            this.setPartMortgageAmount(0, mortgageAmount.value);
        }

        if (typeof callback !== "undefined") {
            callback();
        }
    };

    updateWeightedAvgInterestRate = callback => {
        const { parts, mortgageAmount, interestRate } = this.state;

        let weighted = 0;

        if (parts.parts.length > 1) {
            parts.parts.forEach(parts => {
                weighted += parts.mortgageAmount.value * parts.interestRate.value;
            });

            weighted /= mortgageAmount.value;
        } else {
            weighted = parts.parts[0].interestRate.value;
        }

        this.setState({ interestRate: { ...interestRate, value: weighted } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    updateRateKeyAndLTV = callback => {
        const { mortgageAmount, valueHouse, isNhgPossible, forceNoNhg } = this.state;

        const nhg = isNhgPossible.value ? !forceNoNhg.value : false;

        const newLTV = this.calculateLTV(mortgageAmount.value, valueHouse.value);

        const newRateKey = getRateKey(nhg, newLTV);

        this.setState({ interestRateKey: newRateKey, ltv: { ...newLTV, value: newLTV } }, () => {
            this.updatePartsRates(() => {
                this.requestMaxMortgageAmountFromIncome();
            });
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    addApplicant = callback => {
        const { applicants } = this.state;

        const incomesArray = applicants.incomes;

        incomesArray.push({
            value: 5000,
            conditions: { min: 5000, max: 1000000 },
            errors: { min: "min", max: "max" }
        });

        this.setState({ applicants: { ...applicants, incomes: incomesArray } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    removeApplicant = callback => {
        const { applicants } = this.state;

        const incomesArray = applicants.incomes;

        incomesArray.pop();

        this.setState({ applicants: { ...applicants, incomes: incomesArray } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    setApplicantAmount = (index, newValue, callback) => {
        const { applicants } = this.state;

        const incomesArray = applicants.incomes;

        const validation = this.validate(incomesArray[index], newValue);

        incomesArray[index].value = newValue;
        incomesArray[index].error = validation;

        this.setState({ applicants: { ...applicants, incomes: incomesArray } }, () => {
            if (typeof callback !== "undefined") {
                callback();
            }
        });
    };

    validate = (input, value) => {
        const formatedValue = value !== "" ? parseInt(value) : value;

        if (!("conditions" in input) || formatedValue === input.conditions.equals) {
            return null;
        }
        if (formatedValue < input.conditions.min) {
            return "min";
        }
        if (formatedValue > input.conditions.max) {
            return "max";
        }
        return null;
    };

    validateMortgageAmount = newValue => {
        const { mortgageAmount, maxMortgageAmountIncome, valueHouse } = this.state;
        if (parseInt(newValue) > parseInt(maxMortgageAmountIncome.value)) {
            return "moreThanMaxMortgageIncome";
        }
        if (parseInt(newValue) > parseInt(valueHouse.value)) {
            return "moreThanMaxMortgageHouse";
        }
        return this.validate(mortgageAmount, newValue);
    };

    validateValueHouse = newValue => {
        const { mortgageAmount, valueHouse } = this.state;
        if (parseInt(newValue) < parseInt(mortgageAmount.value)) {
            return "lessThanMortgageAmount";
        }
        return this.validate(valueHouse, newValue);
    };

    validateMortgageAmountAndValueHouse = callback => {
        const { valueHouse, mortgageAmount } = this.state;

        const validationMortgage = this.validateMortgageAmount(mortgageAmount.value);

        const validationHouse = this.validateValueHouse(valueHouse.value);

        this.setState(
            {
                mortgageAmount: { ...mortgageAmount, error: validationMortgage },
                valueHouse: { ...valueHouse, error: validationHouse }
            },
            () => {
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    calculateLTV = (mortgageAmount, valueHouse) => {
        if (mortgageAmount === 0 || mortgageAmount === "" || valueHouse === 0 || valueHouse === "") {
            return 0;
        }

        const value = Math.round((mortgageAmount / valueHouse) * 100);

        return value;
    };

    convertPartsToString = () => {
        const { parts } = this.state;

        const partsJSON = [];

        parts.parts.forEach(part => {
            partsJSON.push({
                mortgage_amount: parseInt(part.mortgageAmount.value),
                interest_fixed_period:
                    part.interestFixedPeriod.value === "variabel"
                        ? part.interestFixedPeriod.value
                        : parseInt(part.interestFixedPeriod.value),
                mortgage_type: part.mortgageType.value,
                duration: parseInt(part.duration.value),
                interest_rate: parseFloat(part.interestRate.value)
            });
        });

        return JSON.stringify(partsJSON);
    };

    convertPartsToStringForRequestForProducts = () => {
        const { parts } = this.state;

        const partsJSON = [];

        parts.parts.forEach(part => {
            partsJSON.push({
                mortgage_amount: part.mortgageAmount.value,
                interest_fixed_period: part.interestFixedPeriod.requestValue,
                mortgage_type: part.mortgageType.requestValue,
                duration: part.duration.value,
                interest_rate: parseFloat(part.interestRate.value)
            });
        });

        return JSON.stringify(partsJSON);
    };

    /**
     * everything wrapped in this context will have access to the state and func
     */
    render() {
        const { children } = this.props;

        const state = this.state;

        return <InputContext.Provider value={state}>{children}</InputContext.Provider>;
    }
}
