import React from "react";

export const SideMenuContext = React.createContext();

export class SideMenuContextComponent extends React.Component {
    static contextType = SideMenuContext;

    constructor(props) {
        super(props);

        this.state = {
            isSideMenuOpen: false,
            drawerWidthOpen: 200,
            drawerWidthClose: 57,
            toggleDrawer: this.toggleDrawer
        };
    }

    toggleDrawer = () => {
        this.setState(state => ({
            isSideMenuOpen: !state.isSideMenuOpen
        }));
    };

    /**
     * everything wrapped in this context will have access to the user object passed from the state
     */
    render() {
        const { children } = this.props;

        const state = this.state;

        return <SideMenuContext.Provider value={state}>{children}</SideMenuContext.Provider>;
    }
}
