import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import services from "./../services";

export const AuthContext = React.createContext();

export class AuthContextComponent extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            isUserAuthenticated: false,
            isAuthStateChanged: false,
            token: null,
            signOut: this.authSignOut
        };
    }

    /**
     * the config needed to init firebase
     * Firebase Console > Authentication > Web setup
     */
    initializeFireBase = () => {
        const config = services.firebase.config;

        firebase.initializeApp(config);
    };

    /**
     * Firebase API Method
     * If the auth state of the user using the application is changed the event is caught here
     */
    onAuthStateChangedEvent = () => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({ user, isUserAuthenticated: true, isAuthStateChanged: true, token: user.ra }, () => {
                    
                    
                });
            } else {
                // No user is signed in.
                this.setState({ user: null, isUserAuthenticated: false, isAuthStateChanged: true, token: null }, () => {
                    
                });
            }
        });
    };

    /**
     * sign out from Google API
     */
    authSignOut = () => {
        
        firebase
            .auth()
            .signOut()
            .then(function() {
                // Sign-out successful.
            })
            .catch(function(error) {
                // An error happened.
            });
    };

    refreshToken = () => {
        
        firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then(idToken => {
                this.setState({ token: idToken }, () => {
                    
                });
            })
            .catch(function(error) {
                // Handle error
            });
    };

    /**
     * upon mounting connect to firebase
     */
    componentDidMount() {
        this.initializeFireBase();
        this.onAuthStateChangedEvent();
        
        //refreshing the token after 10 mins to that when the users leaves the website still for sometime, the back-end does not return forbidden
        setInterval(() => {
            this.refreshToken();
        }, 600000);
    }

    /**
     * everything wrapped in this context will have access to the user object passed from the state
     */
    render() {
        const { children } = this.props;

        const state = this.state;

        return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
    }
}
