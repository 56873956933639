import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import {
    FormControl,
    TextField,
    FormHelperText,
    CircularProgress,
    Button,
    Card,
    CardContent,
    CardActions,
    Fab,
    Typography
} from "@material-ui/core";
import { ReactComponent as GoogleLogo } from "../../Icons/google_logo.svg";
import "./SignInStyles.css";

export default class SignIn extends Component {
    state = {
        email: "",
        password: "",
        errors: {
            email: false,
            password: false
        },
        isLoading: false
    };

    onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    signIn = () => {
        const { email, password, errors } = this.state;

        this.setState({ isLoading: true });

        let _errors = errors;

        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                this.props.history.push("/");
            })
            .catch(error => {
                _errors.email = error.code === "auth/invalid-email" ? true : false;

                _errors.password = error.code === "auth/wrong-password" ? true : false;

                this.setState({ errors: _errors, isLoading: false });
            });
    };

    /**
     * sign with Google API
     */
    signGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        firebase
            .auth()
            .signInWithPopup(provider)
            .then(result => {
                this.props.history.push("/");
            })
            .catch(function(error) {
                
            });
    };

    /**
     * sign with Facebook API
     */
    signFacebook = () => {
        const provider = new firebase.auth.FacebookAuthProvider();

        firebase
            .auth()
            .signInWithPopup(provider)
            .then(function(result) {
                this.props.history.push("/");
            })
            .catch(function(error) {
                
            });
    };

    redirectToSignUp = () => {
        this.props.history.push("/register");
    };

    redirectToResetPassword = () => {
        this.props.history.push("/reset");
    };

    render() {
        const { errors, isLoading } = this.state;
        return (
            <div className={"dom"}>
                <Card className={"card"}>
                    <CardContent className={"card-content"}>
                        <Typography variant={"h2"} style={{ margin: "20px 0" }}>
                            Hyponav
                        </Typography>
                        <FormControl error={errors.email} className={"sign-form-input"}>
                            <TextField
                                error={errors.email}
                                label="Email"
                                variant={"outlined"}
                                className={"sign-input"}
                                type="email"
                                name="email"
                                onChange={this.onInputChange}
                                autoComplete="email"
                                margin="normal"
                            />
                            {errors.email ? (
                                <FormHelperText className={"error-message"}>The email address is badly formatted.</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <FormControl error={errors.password} className={"sign-form-input"}>
                            <TextField
                                error={errors.password}
                                label="Password"
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="password"
                                onChange={this.onInputChange}
                                autoComplete="current-password"
                                margin="normal"
                            />
                            {errors.password ? (
                                <FormHelperText className={"error-message"}>
                                    The password is invalid or the user does not exists.
                                </FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <Button size="large" color="secondary" variant="contained" className={"sign-in-button"} onClick={this.signIn}>
                            {isLoading ? <CircularProgress className={"loader"} /> : "Sign in"}
                        </Button>
                        <div className={"sign-help"}>
                            <Button color="primary" variant={"text"} onClick={this.redirectToResetPassword}>
                                Forgot password?
                            </Button>
                            <Button color="primary" variant={"text"} onClick={this.redirectToSignUp}>
                                Sign up
                            </Button>
                        </div>
                    </CardContent>
                    <CardActions className={"card-actions"}>
                        <Fab color="secondary" style={{ backgroundColor: "#fff", marginRight: 10 }} onClick={this.signGoogle}>
                            <GoogleLogo style={{ height: "30px" }} />
                        </Fab>
                        <Fab color="secondary" style={{ color: "#fff", backgroundColor: "#3b5998" }} onClick={this.signFacebook}>
                            <div className={`icon icon-facebook2`} />
                        </Fab>
                    </CardActions>
                </Card>
            </div>
        );
    }
}
