import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Collapse } from "@material-ui/core";
import { InputContext } from "../../Contexts/InputContext";
import Radio from "./../MortgageDialog/RadioField/RadioField";
import Input from "./../MortgageDialog/InputField/InputField";
import axios from "axios";
import { api } from "./../../services";

class ApplicantsDialog extends Component {
    static contextType = InputContext;

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            calculateIncomeForIndex: 0,
            isSecondaryDialogOpen: false,
            employmentType: "",
            employedEmploymentType: "",
            letterOfIntent: "",
            salaryPeriodType: "",
            salaryAmount: "",
            salaryAmountProps: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            salaryHolidayAllowanceType: "",
            salaryHolidayAmount: "",
            salaryHolidayAmountProps: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            salaryHolidayPercentage: "",
            salaryHolidayPercentageProps: {
                error: null,
                conditions: { min: 0, max: 100 },
                errors: { min: "min", max: "max" }
            },
            incomeYear1: 0,
            incomeYear2: 0,
            incomeYear3: 0,
            incomeYear1Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            incomeYear2Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            incomeYear3Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },

            salaryThirteenthMonth: "",
            salaryThirteenthMonthProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryIrregularitySupplement: "",
            salaryIrregularitySupplementProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryCommission: "",
            salaryCommissionProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryEndOfYearBonus: "",
            salaryEndOfYearBonusProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryOvertime: "",
            salaryOvertimeProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryOther: "",
            salaryOtherProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            }
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open === true && prevProps.open !== this.props.open) {
            this.context.createStateCheckPoint();
        }
    }

    handleMainAction = () => {
        const { onClose } = this.props;
        //some action

        this.context.maxMortgageAmountIncome.updateValue(() => {
            onClose();
            this.context.requestChartsData(() => {
                this.props.logContext.createLog();
            });
        });
    };

    handleCancelAction = () => {
        const { onClose } = this.props;

        this.context.rollbackState(() => {
            onClose();
        });
    };

    openSecondaryDialog = index => {
        this.setState({ isSecondaryDialogOpen: true, calculateIncomeForIndex: index });
    };

    closeSecondaryDialog = () => {
        this.setState({ isSecondaryDialogOpen: false }, () => {
            this.refreshDialogValues();
        });
    };

    onChangeHandler = (name, event) => {
        this.setState({ [name]: event.target.value }, () => {});
    };

    onChangeHandlerTextInput = (name, event) => {
        const inputProps = this.state[`${name}Props`];

        const validation = this.validate(inputProps, event.target.value);

        this.setState({ [name]: event.target.value, [`${name}Props`]: { ...inputProps, error: validation } }, () => {});
    };

    validate = (props, value) => {
        const formatedValue = value !== "" ? parseInt(value) : value;

        if (!("conditions" in props) || formatedValue === props.conditions.equals) {
            return null;
        }
        if (formatedValue < props.conditions.min) {
            return "min";
        }
        if (formatedValue > props.conditions.max) {
            return "max";
        }
        return null;
    };

    handleMainActionSecondModal = () => {
        const formData = new FormData();

        formData.append("employment_type", this.state.employmentType);
        if (this.state.employedEmploymentType !== "") {
            formData.append("employed_employment_type", this.state.employedEmploymentType);
        }
        if (this.state.letterOfIntent !== "") {
            formData.append("letter_of_intent", this.state.letterOfIntent);
        }
        if (this.state.salaryPeriodType !== "") {
            formData.append("salary_period_type", this.state.salaryPeriodType);
        }
        if (this.state.salaryAmount !== "") {
            formData.append("salary", this.state.salaryAmount);
        }
        if (this.state.incomeYear1 !== "") {
            formData.append("income_year_1", this.state.incomeYear1);
        }
        if (this.state.incomeYear2 !== "") {
            formData.append("income_year_2", this.state.incomeYear2);
        }
        if (this.state.incomeYear3 !== "") {
            formData.append("income_year_3", this.state.incomeYear3);
        }
        if (this.state.salaryHolidayAllowanceType !== "") {
            formData.append("salary_allowance_type", this.state.salaryHolidayAllowanceType);
        }
        if (this.state.salaryHolidayAmount !== "") {
            formData.append("salary_holiday_amount", this.state.salaryHolidayAmount);
        }
        if (this.state.salaryHolidayPercentage !== "") {
            formData.append("salary_holiday_percentage", this.state.salaryHolidayPercentage / 100);
        }
        if (this.state.salaryThirteenthMonth !== "") {
            formData.append("salary_thirteenth_month", this.state.salaryThirteenthMonth);
        }
        if (this.state.salaryIrregularitySupplement !== "") {
            formData.append("salary_irregularity_supplement", this.state.salaryIrregularitySupplement);
        }
        if (this.state.salaryCommission !== "") {
            formData.append("salary_commission", this.state.salaryCommission);
        }
        if (this.state.salaryEndOfYearBonus !== "") {
            formData.append("salary_end_of_year_bonus", this.state.salaryEndOfYearBonus);
        }
        if (this.state.salaryOvertime !== "") {
            formData.append("salary_overtime", this.state.salaryOvertime);
        }
        if (this.state.salaryOther !== "") {
            formData.append("salary_other", this.state.salaryOther);
        }
        axios({
            method: "post",
            url: `${api.url}/income`,
            data: formData
        })
            .then(response => {
                const data = response.data.data;

                this.context.applicants.setApplicantAmount(this.state.calculateIncomeForIndex, Math.round(data), () => {
                    this.closeSecondaryDialog();
                });
            })
            .catch(error => {
                
            });
    };

    refreshDialogValues = () => {
        this.setState({
            calculateIncomeForIndex: 0,
            isSecondaryDialogOpen: false,
            employmentType: "",
            employedEmploymentType: "",
            letterOfIntent: "",
            salaryPeriodType: "",
            salaryAmount: "",
            salaryAmountProps: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            salaryHolidayAllowanceType: "",
            salaryHolidayAmount: "",
            salaryHolidayAmountProps: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            salaryHolidayPercentage: "",
            salaryHolidayPercentageProps: {
                error: null,
                conditions: { min: 0, max: 100 },
                errors: { min: "min", max: "max" }
            },
            incomeYear1: 0,
            incomeYear2: 0,
            incomeYear3: 0,
            incomeYear1Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            incomeYear2Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            incomeYear3Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },

            salaryThirteenthMonth: "",
            salaryThirteenthMonthProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryIrregularitySupplement: "",
            salaryIrregularitySupplementProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryCommission: "",
            salaryCommissionProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryEndOfYearBonus: "",
            salaryEndOfYearBonusProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryOvertime: "",
            salaryOvertimeProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryOther: "",
            salaryOtherProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            }
        });
    };

    render() {
        const inputContext = this.context;
        const { open } = this.props;

        return (
            <Fragment>
                <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
                    <DialogTitle>Applicants</DialogTitle>
                    <DialogContent className={"dialog-content"}>
                        <div className={"content-wrapper"}>
                            <div className={"content-side"}>
                                <Radio
                                    onChange={event => {
                                        if (event.target.value === "1") {
                                            inputContext.applicants.removeApplicant();
                                        } else {
                                            inputContext.applicants.addApplicant();
                                        }
                                    }}
                                    inputs={inputContext.applicants.inputs}
                                    value={inputContext.applicants.incomes.length}
                                    label={"Number of applicants"}
                                    name={"number-of-applicants"}
                                />
                                {inputContext.applicants.incomes.map((income, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <Input
                                                id={`income-${index + 1}`}
                                                label={inputContext.applicants.incomes.length === 1 ? "Income" : `Income ${index + 1}`}
                                                value={income.value}
                                                onChange={event => {
                                                    inputContext.applicants.setApplicantAmount(index, event.target.value);
                                                }}
                                                error={income.error}
                                                errors={income.errors}
                                            />
                                            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                                <Button
                                                    color={"secondary"}
                                                    variant={"text"}
                                                    onClick={this.openSecondaryDialog.bind(this, index)}
                                                >
                                                    calculate income
                                                </Button>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className={"content-side"}>
                                “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                                ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                anim id est laborum.”
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancelAction} color="secondary">
                            cancel
                        </Button>
                        <Button onClick={this.handleMainAction} color="secondary" variant={"contained"}>
                            apply
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth={true} maxWidth={"md"} open={this.state.isSecondaryDialogOpen}>
                    <DialogTitle>Calculate income</DialogTitle>
                    <DialogContent className={"dialog-content"}>
                        <div className={"content-wrapper"}>
                            <div className={"content-side"}>
                                <Radio
                                    onChange={event => {
                                        this.onChangeHandler("employmentType", event);
                                    }}
                                    inputs={[{ value: "employed", label: "Employed" }, { value: "self_employed", label: "Self-employed" }]}
                                    value={this.state.employmentType}
                                    label={"Employment type"}
                                    name={"employment-type"}
                                />
                                <Collapse
                                    in={this.state.employmentType === "employed"}
                                    timeout="auto"
                                    unmountOnExit
                                    onExit={() => {
                                        this.setState({ employedEmploymentType: "" });
                                    }}
                                >
                                    <Radio
                                        onChange={event => {
                                            this.onChangeHandler("employedEmploymentType", event);
                                        }}
                                        inputs={[
                                            { value: "permanent", label: "Permanent" },
                                            { value: "flexible", label: "Flexible" },
                                            { value: "temporary", label: "Temporary" }
                                        ]}
                                        value={this.state.employedEmploymentType}
                                        label={"Employed employment type"}
                                        name={"employed-employment-type"}
                                    />
                                </Collapse>
                                <Collapse
                                    in={this.state.employedEmploymentType === "temporary"}
                                    timeout="auto"
                                    unmountOnExit
                                    onExit={() => {
                                        this.setState({ letterOfIntent: "" });
                                    }}
                                >
                                    <Radio
                                        onChange={event => {
                                            this.onChangeHandler("letterOfIntent", event);
                                        }}
                                        inputs={[{ value: "yes", label: "Yes" }, { value: "no", label: "No" }]}
                                        value={this.state.letterOfIntent}
                                        label={"Letter of intent"}
                                        name={"letter-of-intent"}
                                    />
                                </Collapse>
                                <Collapse
                                    in={
                                        this.state.employmentType === "self_employed" ||
                                        this.state.employedEmploymentType === "flexible" ||
                                        this.state.letterOfIntent === "no"
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {[0, 1, 2].map(index => {
                                        return (
                                            <Input
                                                key={index}
                                                id={`income-year-${index + 1}`}
                                                label={`Income year ${index + 1}`}
                                                value={this.state[`incomeYear${index + 1}`]}
                                                onChange={event => {
                                                    this.onChangeHandlerTextInput(`incomeYear${index + 1}`, event);
                                                }}
                                                error={this.state[`incomeYear${index + 1}Props`].error}
                                                errors={this.state[`incomeYear${index + 1}Props`].errors}
                                            />
                                        );
                                    })}
                                </Collapse>
                                <Collapse
                                    in={this.state.employedEmploymentType === "permanent" || this.state.letterOfIntent === "yes"}
                                    timeout="auto"
                                    unmountOnExit
                                    onExit={() => {
                                        this.setState({ salaryPeriodType: "", salaryHolidayAllowanceType: "" });
                                    }}
                                >
                                    <Radio
                                        onChange={event => {
                                            this.onChangeHandler("salaryPeriodType", event);
                                        }}
                                        inputs={[
                                            { value: "yearly", label: "Yearly" },
                                            { value: "monthly", label: "Monthly" },
                                            { value: "weekly", label: "Weekly" }
                                        ]}
                                        value={this.state.salaryPeriodType}
                                        label={"Salary period type"}
                                        name={"salary-period-type"}
                                    />
                                    <Collapse
                                        in={this.state.salaryPeriodType !== ""}
                                        timeout="auto"
                                        unmountOnExit
                                        onExit={() => {
                                            this.setState({ salaryAmount: "" });
                                        }}
                                    >
                                        <Input
                                            id={"salary-amount"}
                                            label={"Salary amount"}
                                            value={this.state.salaryAmount}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryAmount", event);
                                            }}
                                            error={this.state.salaryAmountProps.error}
                                            errors={this.state.salaryAmountProps.errors}
                                        />
                                        <Input
                                            id={"salaryThirteenthMonth"}
                                            label={"Salary thirteenth month"}
                                            value={this.state.salaryThirteenthMonth}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryThirteenthMonth", event);
                                            }}
                                            error={this.state.salaryThirteenthMonthProps.error}
                                            errors={this.state.salaryThirteenthMonthProps.errors}
                                        />
                                        <Input
                                            id={"salaryIrregularitySupplement"}
                                            label={"Salary irregularity supplement"}
                                            value={this.state.salaryIrregularitySupplement}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryIrregularitySupplement", event);
                                            }}
                                            error={this.state.salaryIrregularitySupplementProps.error}
                                            errors={this.state.salaryIrregularitySupplementProps.errors}
                                        />
                                        <Input
                                            id={"salaryCommission"}
                                            label={"Salary commission"}
                                            value={this.state.salaryCommission}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryCommission", event);
                                            }}
                                            error={this.state.salaryCommissionProps.error}
                                            errors={this.state.salaryCommissionProps.errors}
                                        />
                                        <Input
                                            id={"salaryEndOfYearBonus"}
                                            label={"Salary end of year bonus"}
                                            value={this.state.salaryEndOfYearBonus}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryEndOfYearBonus", event);
                                            }}
                                            error={this.state.salaryEndOfYearBonusProps.error}
                                            errors={this.state.salaryEndOfYearBonusProps.errors}
                                        />
                                        <Input
                                            id={"salaryOvertime"}
                                            label={"Salary overtime"}
                                            value={this.state.salaryOvertime}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryOvertime", event);
                                            }}
                                            error={this.state.salaryOvertimeProps.error}
                                            errors={this.state.salaryOvertimeProps.errors}
                                        />
                                        <Input
                                            id={"salaryOther"}
                                            label={"Salary other"}
                                            value={this.state.salaryOther}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryOther", event);
                                            }}
                                            error={this.state.salaryOtherProps.error}
                                            errors={this.state.salaryOtherProps.errors}
                                        />
                                    </Collapse>
                                    <Radio
                                        onChange={event => {
                                            this.onChangeHandler("salaryHolidayAllowanceType", event);
                                        }}
                                        inputs={[
                                            { value: "standard", label: "Standard" },
                                            { value: "amount", label: "Amount" },
                                            { value: "percentage", label: "Percentage" }
                                        ]}
                                        value={this.state.salaryHolidayAllowanceType}
                                        label={"Salary holiday allowance type"}
                                        name={"salary-holiday-allowance-type"}
                                    />
                                    <Collapse
                                        in={this.state.salaryHolidayAllowanceType === "amount"}
                                        timeout="auto"
                                        unmountOnExit
                                        onExit={() => {
                                            this.setState({ salaryHolidayAmount: "" });
                                        }}
                                    >
                                        <Input
                                            id={"salary-holiday-amount"}
                                            label={"Salary holiday amount"}
                                            value={this.state.salaryHolidayAmount}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryHolidayAmount", event);
                                            }}
                                            error={this.state.salaryHolidayAmountProps.error}
                                            errors={this.state.salaryHolidayAmountProps.errors}
                                        />
                                    </Collapse>
                                    <Collapse
                                        in={this.state.salaryHolidayAllowanceType === "percentage"}
                                        timeout="auto"
                                        unmountOnExit
                                        onExit={() => {
                                            this.setState({ salaryHolidayPercentage: "" });
                                        }}
                                    >
                                        <Input
                                            id={"salary-percentage-amount"}
                                            label={"Salary percentage"}
                                            value={this.state.salaryHolidayPercentage}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryHolidayPercentage", event);
                                            }}
                                            error={this.state.salaryHolidayPercentageProps.error}
                                            errors={this.state.salaryHolidayPercentageProps.errors}
                                        />
                                    </Collapse>
                                </Collapse>
                            </div>
                            <div className={"content-side"}>
                                “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                                ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                anim id est laborum.”
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeSecondaryDialog} color="secondary">
                            cancel
                        </Button>
                        <Button onClick={this.handleMainActionSecondModal} color="secondary" variant={"contained"}>
                            Calculate
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default withTheme()(ApplicantsDialog);
